import React from 'react';

import styles from './no-data.module.css';

type Props = {};

export default function NoData({}: Props) {
  return (
    <div className={styles['no-data-img']}>
      <img src="/images/noData.svg" alt="no-data" />
    </div>
  );
}
