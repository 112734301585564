import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap';

import {
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttachmentList from 'src/components/attachment-list/attachment-list';
import AvatarRow from 'src/components/avatar-row/avatar-row';
import NewComment from 'src/components/post/new-comment';
import { UserContext } from 'src/context/user.context';
import {
  deleteComment,
  deleteCommentUpvote,
  fetchAllPostUpvotes,
  getCommentReplies,
  postCommentUpvote,
} from 'src/services/post-service/post-service';
import {
  IComment,
  IPost,
  IReply,
} from 'src/services/post-service/post-service.interface';
import { formatDateStr } from 'src/utils/dateUtil';
import styles from './question.module.css';
import ReplyView from './reply';

type IProps = {
  comment: IComment;
  selectedQues: IPost;
  onDelete: (commentId: string) => void;
  onEdit: (comment: IComment) => void;
};

const CommentView = ({
  comment: initialComment,
  selectedQues,
  onDelete,
  onEdit,
}: IProps) => {
  const { id } = useContext(UserContext);
  const [comment, setComment] = useState(initialComment);
  const [showReplies, setShowReplies] = useState<boolean>(true);
  const [selectedReplies, setSelectedReplies] = useState<Array<IReply>>();
  const [newComment, setNewComment] = useState<boolean>(false);
  const [loadingReplies, seLoadingReplies] = useState(false);
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);
  const [showCommentMenu, setShowCommentMenu] = useState(false);

  const [loadingUserReplies, setLoadingUserReplies] = useState(false);
  const [loadingUserUpvotes, setLoadingUserUpvotes] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [editReply, setEditReply] = useState<IReply>();

  const fetchUserReplies = async () => {
    if (selectedQues) {
      setLoadingUserReplies(true);
      const userReplies = (
        await getCommentReplies(selectedQues._id, comment._id, {
          pageNumber: 1,
          pageSize: 1,
        })
      ).data;
      setComment((prevComment) => ({ ...prevComment, replies: userReplies }));
      setLoadingUserReplies(false);
    }
  };

  useEffect(() => {
    fetchUserReplies();
    fetchReplies();
  }, []);
  const loggedInUserId = localStorage.getItem('id') || '';

  const fetchReplies = async () => {
    if (selectedQues) {
      seLoadingReplies(true);
      const reply = await getCommentReplies(selectedQues._id, comment._id);
      const newReplyList = [...reply.data];

      const postIds = newReplyList.map((comment) => comment.id).filter(Boolean);
      const response = await fetchAllPostUpvotes(postIds, 'reply');

      const upvotesMap = response.data.data;

      const updatedCommentList = newReplyList.map((reply) => ({
        ...reply,
        postUpvotes: upvotesMap[reply._id] || [],
      }));
      const replyListInterseted = updatedCommentList.map((reply) => ({
        ...reply,
        interested: reply?.postUpvotes.some(
          (item: any) => item._userId === loggedInUserId
        ),
      }));

      setSelectedReplies(replyListInterseted);
      seLoadingReplies(false);
    }
  };

  const addUpvote = async () => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      const upvote = await postCommentUpvote(selectedQues._id, comment._id);
      setComment((prevComment) => ({
        ...prevComment,
        upvotes: [{ ...upvote, _userId: user }],
        upvoteCount: (prevComment.upvoteCount || 0) + 1,
        interested: true,
      }));
      setLoadingUpvotes(false);
    }
  };

  const removeUpvote = async (userId: string) => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      await deleteCommentUpvote(selectedQues._id, comment._id, userId);

      setComment((prevComment) => ({
        ...prevComment,
        upvotes: [],
        interested: false,
        upvoteCount: prevComment.upvoteCount ? prevComment.upvoteCount - 1 : 0,
      }));
      setLoadingUpvotes(false);
    }
  };

  const haveCommented = selectedReplies?.find(
    ({ _userId }) => _userId?._id === id
  );

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const profileId = localStorage.getItem('id') || '';

  const isWork = selectedQues?.type === 'WORK';

  const self = comment?._userId._id === id;

  const handleDelete = async () => {
    if (selectedQues) {
      setUpdating(true);
      await deleteComment(selectedQues?._id, comment.id);
      onDelete(comment.id);
      setUpdating(false);
    }
  };

  return (
    <div className={styles['qa-container']} key={comment._id}>
      <div className={styles['result-avatar']}>
        <AvatarRow showMembership data={comment._userId} />
        <span className={styles['comment-date']}>
          {formatDateStr(comment.createdOn)}
        </span>
      </div>
      <p
        className={styles.questionContent}
        dangerouslySetInnerHTML={{ __html: comment.content }}
      />
      {comment?.attachments?.length > 0 && (
        <AttachmentList attachments={comment?.attachments} />
      )}
      <div className={styles['item-interaction']}>
        <div className={styles['item-interaction-left']}>
          <Button
            className={styles['interaction-btn']}
            color="transparent"
            onClick={() => setShowReplies(!showReplies)}
          >
            <img
              src={
                haveCommented
                  ? '/images/commentedIcon.png'
                  : '/images/commentIcon.png'
              }
              alt=""
            />
            &nbsp;{isWork ? 'Reply' : 'Comment'} ({comment.replyCount})
            {loadingReplies ||
              (loadingUserReplies && (
                <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
              ))}
          </Button>
          {selectedQues?.type !== 'WORK' && (
            <Button
              className={styles['interaction-btn']}
              color="transparent"
              onClick={
                comment?.interested ? () => removeUpvote(profileId) : addUpvote
              }
            >
              <img
                src={
                  comment.interested
                    ? '/images/upvotedIcon.png'
                    : '/images/upvoteIcon.png'
                }
                alt=""
              />
              &nbsp;Upvote ({comment.upvoteCount || 0})
              {loadingUpvotes ||
                (loadingUserUpvotes && (
                  <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                ))}
            </Button>
          )}
        </div>
        <div className={styles['item-interaction-right']}>
          {(self ||
            ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(user.role)) && (
            <>
              <Dropdown
                className={styles.menuButton}
                isOpen={showCommentMenu}
                toggle={() => setShowCommentMenu((old) => !old)}
                direction="down"
              >
                <DropdownToggle>
                  {updating ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      style={{ height: '14px' }}
                    />
                  )}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => onEdit(comment)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          )}
        </div>
      </div>
      {showReplies && (
        <>
          <hr style={{ marginTop: '12px' }} />
          <div>
            {selectedReplies?.map(
              (reply) =>
                !(editReply?._id === reply.id && newComment) && (
                  <ReplyView
                    key={reply._id}
                    onEdit={(reply) => {
                      setEditReply(reply);
                      setNewComment(true);
                    }}
                    onDelete={(replyId) => {
                      setSelectedReplies((prevReplies) => {
                        return [
                          ...(prevReplies || []).filter(
                            ({ _id }) => _id !== replyId
                          ),
                        ];
                      });
                      setComment((prevComment) => ({
                        ...prevComment,
                        replyCount: prevComment.replyCount
                          ? prevComment.replyCount - 1
                          : 0,
                      }));
                    }}
                    comment={comment}
                    selectedQues={selectedQues}
                    reply={reply}
                  />
                )
            )}

            {!newComment ? (
              <Input
                type="text"
                className={styles['add-answer-input']}
                placeholder={isWork ? 'Reply here' : 'Comment here'}
                onClick={() => setNewComment(true)}
              />
            ) : (
              <NewComment
                initialReplyData={editReply}
                onCreate={(reply) => {
                  setNewComment(false);
                  setSelectedReplies((prevReplies) => {
                    return [
                      { ...reply, _userId: user },
                      ...(prevReplies || []),
                    ];
                  });
                  setComment({
                    ...comment,
                    replyCount: (comment.replyCount || 0) + 1,
                  });
                }}
                onUpdateReply={(reply) => {
                  fetchReplies();
                }}
                isReply={isWork}
                selectedQues={selectedQues}
                selectedComm={comment}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CommentView;
