import React, { useState, useEffect } from 'react';

function Timer({
  initialTime,
  onTimeout,
}: {
  initialTime: any;
  onTimeout: any;
}) {
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    } else {
      onTimeout();
    }
  }, [timeRemaining, onTimeout]);

  return (
    <div>
      <p style={{ color: 'red', marginLeft: '10px' }}>
        Time Remaining: {timeRemaining} seconds
      </p>
    </div>
  );
}

export default Timer;
