import Layout from '../adminDashboard/layout';
import 'react-datepicker/dist/react-datepicker.css';
import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import {
  getMenuData,
  updateMetaData,
} from 'src/services/meta-data-service/meta-data-service';
import { IMetaData } from 'src/services/meta-data-service/meta-data-interface';
import { IUser } from 'src/services/user-service/user-service.interface';
import Select from 'react-select';
import { LoadingLottie } from '../lotties/loading';
import { useNavigate } from 'react-router-dom';

const CreateSignature = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role === 'USER') {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tableData, setTableData] = useState<IMetaData[]>([]);
  const [isVerified, setIsVerified] = useState<any>({
    value: 0,
    label: 'Open',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(1);

  const options = [
    { value: 0, label: 'Open' },
    { value: 1, label: 'Close' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const result = await getMenuData(!!isVerified.value, true);
        const resultData = result.data;
        setLoader(false);
        setTableData(() => [...resultData]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoader(false);
      }
    };

    fetchData();
  }, [isVerified, refresh]);

  const handleMetaToggle = async (metaData: IMetaData) => {
    setLoader(true);
    const request = {
      isVerified: !metaData.isVerified,
    };
    await updateMetaData(metaData._id, request);
    setRefresh(refresh + 1);
    setLoader(false);
  };

  if (loader) {
    return <LoadingLottie height={'700px'} />;
  }

  return (
    <Layout contentBordered={true}>
      <div className="wrapper">
        <div className="heading">
          <div className="headingTitle">Create Category</div>
        </div>
        <div>
          <Select
            value={isVerified}
            onChange={(selected: any) => setIsVerified(selected)}
            //@ts-ignore
            options={options}
          />
        </div>
        <div>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Email Id</th>
                <th>Category</th>
                <th>Description</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row._id}</td>
                  <td>{(row._userId as IUser)?.name}</td>
                  <td>{(row._userId as IUser)?.emailAddress}</td>
                  <td>{row.name}</td>
                  <td>{row.description}</td>
                  <td>{row.updatedOn.split('T')[0]}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={row.isVerified}
                      onChange={() => handleMetaToggle(row)}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default CreateSignature;
