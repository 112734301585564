import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { IUser } from 'src/services/user-service/user-service.interface';
import { getFollowings } from 'src/services/user-service/user-servicec';

interface IUserContext {
  id: string;
  following: IUser[];
  removeFollowing: (newFollowing: any) => void;
  addFollowing: (newFollowing: any) => void;
}

const UserContext = createContext<IUserContext>({
  id: '',
  following: [],
  removeFollowing: (newFollowing) => console.log(newFollowing),
  addFollowing: (newFollowing) => console.log(newFollowing),
});

const UserProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [following, setFollowing] = useState<Array<any>>([]);
  const [id, setId] = useState<string>('');

  const fetchUserData = async (_id: string) => {
    const res = await getFollowings(_id || '');
    setFollowing(res?.data);
    setId(_id);
  };

  useEffect(() => {
    if (following.length === 0) {
      const _id = localStorage.getItem('id') as string;
      if (_id) {
        fetchUserData(_id);
      }
    }
  }, []);

  const addFollowing = (newFollowing: any) => {
    setFollowing((prevFollowings) => {
      return [...prevFollowings, newFollowing];
    });
  };

  const removeFollowing = (newFollowing: any) => {
    setFollowing((prevFollowings) => {
      const updatedFollowings = prevFollowings.filter(
        ({ _id }: any) => newFollowing._id !== _id
      );
      return updatedFollowings;
    });
  };

  return (
    <UserContext.Provider
      value={{ id, following, addFollowing, removeFollowing }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
