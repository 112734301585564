import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST, // Replace with your API base URL
});

// Set token for every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Retrieve token from local storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle error responses
    if (error.response.status === 401) {
      window.location.href = '/';
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
