import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserNotifications } from 'src/services/notification-service/notification-service';
import { LoadingLottie } from '../lotties/loading';
import { resolveBEAsset } from 'src/utils/urls';

import {
  IUserNotification,
  IUserNotificationResponse,
} from 'src/services/notification-service/notification-interface';
import Layout from 'src/layouts/layout';
import BottomBar from '../bottombar/BottomBar';

const RecentUpdate = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [userNotifications, setUserNotifications] = useState<
    IUserNotification[]
  >([]);
  const id = localStorage.getItem('id') || '';
  const fetchUserNotifications = async () => {
    setLoader(true);
    let res: IUserNotificationResponse = {
      data: [],
      total: 0,
      pageSize: 0,
      pageNumber: 0,
    };
    if (id) {
      res = await getUserNotifications(id);
    }
    setUserNotifications(res.data);
    setLoader(false);
  };

  useEffect(() => {
    // if (showSettings) {
    fetchUserNotifications();
    // }
    return () => {
      setUserNotifications([]);
    };
  }, []);

  const formatUserNotification = (noti: IUserNotification) => {
    switch (noti.type) {
      case 'POST':
        return (
          <>
            <b>{noti.userId.name}</b> has posted an update
          </>
        );
      case 'COMMENT':
        return (
          <>
            <b>{noti?.userId?.name}</b> has commeted on your post
          </>
        );
      case 'REPLY':
        return (
          <>
            <b>{noti.userId.name}</b> has replied in your post
          </>
        );
      case 'POST_UPVOTE':
        return (
          <>
            <b>{noti.userId.name}</b> has upvoted in your post
          </>
        );
      case 'COMMENT_UPVOTE':
        return (
          <>
            <b>{noti.userId.name}</b> has upvoted your comment
          </>
        );
      case 'REPLY_UPVOTE':
        return (
          <>
            <b>{noti.userId.name}</b> has upvoted your reply
          </>
        );
      default:
        return '';
    }
  };

  const filteredNotifications = userNotifications.filter((noti) => {
    const notificationDate = new Date(noti.createdOn);
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    return notificationDate > thirtyDaysAgo;
  });

  return (
    <Layout contentBordered={true}>
      <div
        style={{
          marginTop: '70px',
          marginBottom: '35px',
        }}
      >
        {loader ? <LoadingLottie height="200px" /> : null}

        {filteredNotifications.map((noti) => (
          <div
            key={noti?._id}
            style={{
              marginBottom: '10px',
              borderBottom: '1px solid #e6e6e6',
              padding: '5px 0',
            }}
          >
            <Link
              to={`/post?postId=${noti._postId}`}
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <div
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <img
                  src={
                    noti.userId?.picture?.path
                      ? resolveBEAsset(noti.userId?.picture?.path || '')
                      : '/images/defaultProfilePic.svg'
                  }
                  alt="user profile"
                  width={35}
                  height={35}
                  style={{ borderRadius: '50%', border: '1px solid #e6e6e6' }}
                />

                <div>{formatUserNotification(noti)}</div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {window.innerWidth < 900 && <BottomBar />}
    </Layout>
  );
};

export default RecentUpdate;
