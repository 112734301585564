import axiosInstance from 'src/config/api';

const baseUrl = process.env.REACT_APP_API_HOST;

export const fetchRecentChatList = async (userId: string) => {
  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/user/${userId}/message?sortBy=updatedOn&sortOrder=desc`
    )
  ).data;
};

export const postMessage = async (payload: any) => {
  return (await axiosInstance.post(`${baseUrl}/api/v1/message`, payload)).data;
};

export const getMessage = async (userId: string) => {
  return (await axiosInstance.get(`${baseUrl}/api/v1/message?userId=${userId}`))
    .data;
};

export const deleteMessage = async (userId: string) => {
  return (await axiosInstance.delete(`${baseUrl}/api/v1/message/${userId}`))
    .data;
};

export const getMessageForTwoMutualUser = async (
  otherUserId: string,
  yourUserId: string
) => {
  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/message?userId=${otherUserId}&_userId=${yourUserId}&userId=${yourUserId}&_userId=${otherUserId}&populate=_userId&sortBy=updatedOn&sortOrder=asc`
    )
  ).data;
};
