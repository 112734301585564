import React, { useEffect, useState } from 'react';

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  deleteTrend,
  deleteTrendComment,
  editTrend,
} from 'src/services/trend-service/trend-service';

import { faCaretDown, faClock } from '@fortawesome/free-solid-svg-icons';
import styles from '../../pages/trend/trend.module.css';
import { resolveBEAsset } from 'src/utils/urls';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill';
import { formats, modules } from '../../pages/trend/trendHelper';

type Props = {
  trendId: string;
  type: string;
  user?: any;
  initialContent?: string;
};

export default function TrendActions({
  trendId,
  type,
  user,
  initialContent,
}: Props) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [charExceeds, setCharExceeds] = useState(false);
  const [trendTimer, setTrendTimer] = useState(24);
  const [desc, setDesc] = useState(initialContent || '');
  const [modalShow, setModalShow] = useState(false);
  const [timerDropdown, setTimerDropdown] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const maxChars = 1024;
  const router = useNavigate();
  const handleEditTrend = async () => {
    await editTrend(trendId, desc, trendTimer);

    urlParams.set('trendCreated', `true`);
    router(`/trending?${urlParams.toString()}`);
    setModalShow(false);
  };
  const handleDelete = async () => {
    if (type === 'trend') {
      await deleteTrend(trendId);
    }
    if (type === 'trendComment') {
      await deleteTrendComment(trendId);
    }
    urlParams.set('trendCreated', `true`);
    router(`/trending?${urlParams.toString()}`);
  };
  const handleDescChange = (value: any) => {
    setDesc(value);
  };

  useEffect(() => {
    const plainText = desc.replace(/<\/?[^>]+(>|$)/g, '');
    setCharCount(plainText.length);
    setCharExceeds(plainText.length > maxChars);
  }, [desc]);

  return (
    <>
      <Dropdown
        className={styles.menuButton}
        isOpen={showMenu}
        toggle={() => setShowMenu((old) => !old)}
        direction="down"
      >
        <DropdownToggle>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </DropdownToggle>
        <DropdownMenu>
          {type === 'trend' && (
            <DropdownItem onClick={() => setModalShow(true)}>
              <div>Edit</div>
            </DropdownItem>
          )}
          <DropdownItem>
            <div onClick={() => handleDelete()}>Delete</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {user && (
        <Modal
          show={modalShow}
          centered
          onHide={() => {
            setModalShow(false);
          }}
          toggle={() => setModalShow((prev) => !prev)}
          className={styles['createTrendModal']}
        >
          <Modal.Header
            closeButton
            style={{ borderBottom: 'none', padding: '12px 16px 0' }}
          >
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {user && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={
                      user?.picture
                        ? resolveBEAsset(user?.picture?.path || '')
                        : '/images/defaultProfilePic.svg'
                    }
                    className={styles.userPicture}
                    alt="userImage"
                  />
                  &nbsp;{' '}
                  <Dropdown
                    isOpen={timerDropdown}
                    toggle={() => setTimerDropdown(!timerDropdown)}
                    className={styles['sort-dropdown-mobile']}
                  >
                    <DropdownToggle
                      style={{
                        display: 'flex',
                        background: 'none',
                        color: 'black',
                        alignItems: 'center',
                        border: 'none',
                        fontSize: '14px',
                      }}
                    >
                      <Button
                        onClick={() => {
                          setTimerDropdown(true);
                        }}
                        className={styles['noit-professional-only']}
                      >
                        {trendTimer === 24 ? (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <FontAwesomeIcon icon={faClock} />
                            &nbsp; 24 hours &nbsp;{' '}
                            <FontAwesomeIcon icon={faCaretDown} />
                          </div>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faClock} />
                            &nbsp; 48 hours &nbsp;{' '}
                            <FontAwesomeIcon icon={faCaretDown} />
                          </>
                        )}
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu>
                      <>
                        <DropdownItem
                          onClick={() => setTrendTimer(24)}
                          style={{
                            whiteSpace: 'break-spaces',
                            lineHeight: 'initial',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12px',
                              fontWeight: '500',
                              textDecoration: 'underline',
                            }}
                          >
                            24 hours
                          </span>
                          <br />
                          <span style={{ fontSize: '12px' }}>
                            This trend shall be available for 24 hours.
                          </span>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setTrendTimer(48)}
                          style={{
                            whiteSpace: 'break-spaces',
                            lineHeight: 'initial',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12px',
                              fontWeight: '500',
                              textDecoration: 'underline',
                            }}
                          >
                            48 hours
                          </span>
                          <br />
                          <span style={{ fontSize: '12px' }}>
                            This trend shall be available for 48 hours.
                          </span>
                        </DropdownItem>
                      </>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '0 16px 16px' }}>
            {' '}
            <ReactQuill
              theme="snow"
              value={desc}
              formats={formats}
              modules={modules}
              onChange={handleDescChange}
              className={styles['text-editor-quill']}
              placeholder="Enter your Trends Text"
            />{' '}
            <div
              className={`${styles['char-count']} ${
                charExceeds ? styles['char-count-exceed'] : ''
              }`}
            >
              {charCount} / {maxChars} characters
            </div>
            <div
              className={styles['dashboard-search-attachment-list']}
              style={{
                justifyContent: 'flex-end',
                padding: '0 12px',
                marginTop: '12px',
              }}
            >
              <button
                onClick={handleEditTrend}
                disabled={charExceeds}
                className={styles['postBtn']}
              >
                Update Trend
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
