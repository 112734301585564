import Sidebar from './leftPannel';
import Navbar from './navBar';
import React from 'react';
import styles from 'src/layouts/layout.module.css';
import classNames from 'classnames';

const Layout = ({ children, contentBordered }) => {
  const currentUrl = window.location.href;
  const isAdminActive =
    currentUrl.includes('/adminPannel') ||
    currentUrl.includes('push-notification') ||
    currentUrl.includes('/create-signature');
  return (
    // <MenuProvider>
    //   <UserProvider>
    <div
      className={classNames(
        isAdminActive ? styles['admin-active'] : '',
        styles['wc-layout']
      )}
    >
      <Navbar />
      <Sidebar />
      <div className={styles['wc-content-container']}>
        <div
          className={classNames(
            styles['wc-content'],
            contentBordered ? styles['with-border'] : ''
          )}
        >
          {children}
        </div>
      </div>
    </div>
    //   </UserProvider>
    // </MenuProvider>
  );
};

export default Layout;
