import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SuccessLottie } from '../lotties/success';
import { LoadingLottie } from '../lotties/loading';
import StepTwoForm from '../stepper/step2/step2';
import styles from './new-membership.module.css';
import { IMembership } from 'src/services/user-service/user-service.interface';
import {
  fetchMembership,
  updateMembershipNumber,
  updateUserProfession,
} from 'src/services/user-service/user-servicec';
import { validateMembershipData } from './membership.helper';

type Props = {
  showAddMembership: boolean;
  setShowAddMembership: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function NewMembership({
  showAddMembership,
  setShowAddMembership,
}: Props) {
  const [membership, setMembership] = useState<IMembership[]>([]);
  const [membershipFormData, setMembershipFormData] = useState<any>({});
  const [uploadDocsObject, setUploadDocsObject] = useState(null);
  const [membershipFormError, setMembershipFormError] = useState<any>({});
  const [loadingMembership, setLoadingMembership] = useState(false);
  const [successMembership, setSuccessMembership] = useState(false);
  const userString = localStorage.getItem('user') || '';
  const loggedInuser = userString ? JSON.parse(userString) : '';
  const profileId = loggedInuser._id;
  const token = localStorage.getItem('token') || '';

  const getMembership = async () => {
    const res = await fetchMembership(profileId);
    setMembership(res.data);
  };
  useEffect(() => {
    if (profileId) {
      getMembership();
    }
  }, []);

  const handleFormChange = (e: any) => {
    if (e.target.type === 'file') {
      setUploadDocsObject(e.target.files);
    }
    setMembershipFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));
  };
  const onUpdateMembership = async () => {
    const newErrors = validateMembershipData(membershipFormData);
    if (
      membershipFormData.noneSelected &&
      membership.some((m) => m.name.includes('NONE'))
    ) {
      return setShowAddMembership(false);
    }
    if (
      membershipFormData.noneSelected &&
      !membership.some((m) => m.name.includes('NONE'))
    ) {
      let updateUserPayload = {
        name: 'NONE',
        number: 'None Selected',
        attachments: [],
      };
      setLoadingMembership(true);

      const updatedUserNoneSelected = await updateUserProfession(
        profileId,
        token,
        updateUserPayload
      );

      setMembership((prev) => [...prev, updatedUserNoneSelected]);
      setLoadingMembership(false);
      setShowAddMembership(false);
      window.location.reload();
    }

    if (Object.keys(newErrors).length === 0) {
      setLoadingMembership(true);

      const membershipTypes = ['ICAI', 'ICMAI', 'ICSI', 'BCI'];
      for (const type of membershipTypes) {
        if (
          membershipFormData[type.toLowerCase()] &&
          !membershipFormData[`${type.toLowerCase()}_disabled`]
        ) {
          const existingMembership = membership.find((m) => m.name === type);
          const updateUserPayload = {
            name: type,
            number: membershipFormData[`${type.toLowerCase()}_membership_no`],
            attachments: [],
          };

          if (existingMembership) {
            // If membership already exists, update the number
            const updatedMembership = await updateMembershipNumber(
              profileId,
              token,
              updateUserPayload.name,
              updateUserPayload.number
            );
            // Update the state to reflect the updated membership
            setMembership((prev) =>
              prev.map((m) =>
                m._id === existingMembership._id
                  ? { ...m, ...updatedMembership }
                  : m
              )
            );
          } else {
            // If membership does not exist, add a new one

            const newMembership = await updateUserProfession(
              profileId,
              token,
              updateUserPayload
            );
            setMembership((prev) => [...prev, newMembership]);
          }
        }
      }
      setLoadingMembership(false);
      setSuccessMembership(true);
      setTimeout(() => {
        setSuccessMembership(false);
        setShowAddMembership(false);
        window.location.reload();
      }, 2500);
    } else {
      setMembershipFormError(newErrors);
    }
  };

  const currentUrl = window.location.href;
  return (
    <Modal
      className={styles['new-member-modal']}
      isOpen={showAddMembership}
      toggle={() => setShowAddMembership((prevShowModal) => !prevShowModal)}
    >
      {!successMembership && !loadingMembership && (
        <ModalHeader
          toggle={() => setShowAddMembership((prevShowModal) => !prevShowModal)}
        >
          <div className={styles['addMebership-title']}>
            {currentUrl.includes('/dashboard') ? (
              <>Do you hold any Membership? </>
            ) : (
              'Add Membership'
            )}
          </div>
        </ModalHeader>
      )}
      <ModalBody>
        {successMembership && <SuccessLottie height={410} />}
        {loadingMembership && <LoadingLottie height={410} />}
        {!successMembership && !loadingMembership && (
          <div className="stepper-content">
            <StepTwoForm
              error={membershipFormError}
              handleChange={handleFormChange}
              onSubmit={onUpdateMembership}
              formValue={membershipFormData}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
