import React, { useEffect, useState } from 'react';
import { fetchMembership } from 'src/services/user-service/user-servicec';
import styles from './rightbar.module.css';
export default function UserMembership() {
  const [isVerificationPending, setIsVerificationPending] = useState(false);
  const profileId = localStorage.getItem('id') || '';
  const getMembership = async () => {
    const res = await fetchMembership(profileId);

    if (
      res.data.some(
        (item: any) => item.isVerified === false && item.name !== 'NONE'
      )
    ) {
      setIsVerificationPending(true);
    }
  };
  useEffect(() => {
    getMembership();
  }, []);
  if (!isVerificationPending) {
    return null;
  }
  return (
    <>
      {isVerificationPending && (
        <div className={styles['rightbar-element-wrapper']}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              padding: '10px',
              fontSize: '14px',
            }}
          >
            Your Profile is currently under review and will get active within
            24hours
          </div>
        </div>
      )}
    </>
  );
}
