import React, { useState } from 'react';
import Stepper from './stepper';
import StepOneForm from '../stepper/step1/step1';
import StepTwoForm from '../stepper/step2/step2';
import {
  createUser,
  updateUserProfession,
  uploadUserAttachment,
} from 'src/services/user-service/user-servicec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ErrorDialog from 'src/components/utils/ErrorDialog';
import Loader from 'src/components/utils/Loader';
import { useNavigate } from 'react-router-dom';

function gtag_report_conversion(url) {
  const callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  // eslint-disable-next-line no-undef
  gtag('event', 'conversion', {
    send_to: 'AW-11329981835/TVwiCKmDh6IZEIubx5oq',
    event_callback: callback,
  });
  return false;
}

const SignupForm = () => {
  const message = `Thank you for Joining The WCP Community ! Currently your Account is under Review, will get active within 24-48 Hrs`;
  const styleForMessage = {
    color: '#00BCD4',
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const route = useNavigate();
  const [showAnimation, setShowAnimation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [errorsForm1, setErrorsForm1] = useState({});
  const [errorsForm2, setErrorsForm2] = useState({});
  const [errorform1, setErrorForm1] = useState(false);
  // const [errorsForm3, setErrorsForm3] = useState({});
  const [createdUserId, setCreatedUserId] = useState('');
  const [createdUserToken, setCreatedUserToken] = useState('');
  const [uploadDocsObject, setUploadDocsObject] = useState(null);

  const validateStep1Form = () => {
    let newErrors = {};

    if (!stepOneFormData.phone) {
      newErrors.phone = 'Phone is required.';
    }

    if (!stepOneFormData.aadhar) {
      newErrors.aadhar = 'Aadhar Number is required.';
    }
    if (!stepOneFormData.otp) {
      newErrors.otp = 'OTP Required';
    }
    if (!stepOneFormData.email) {
      newErrors.email = 'Email is required.';
    }
    if (!stepOneFormData.password) {
      newErrors.password = 'Password is required.';
    }
    return newErrors;
  };

  const validateStep2Form = () => {
    let newErrors = {};
    const errorMsgName = 'Error: Details are mandate';
    const errorMsgMemNumber = 'Error: Details are mandate';
    if (stepTwoFormData.icai) {
      if (!stepTwoFormData.icai_name) {
        newErrors.icai_name = errorMsgName;
      }
      if (!stepTwoFormData.icai_membership_no) {
        newErrors.icai_membership_no = errorMsgMemNumber;
      }
    }
    if (stepTwoFormData.icsi) {
      if (!stepTwoFormData.icsi_name) {
        newErrors.icsi_name = errorMsgName;
      }
      if (!stepTwoFormData.icsi_membership_no) {
        newErrors.icsi_membership_no = errorMsgMemNumber;
      }
    }
    if (stepTwoFormData.icmai) {
      if (!stepTwoFormData.icmai_name) {
        newErrors.icmai_name = errorMsgName;
      }
      if (!stepTwoFormData.icmai_membership_no) {
        newErrors.icmai_membership_no = errorMsgMemNumber;
      }
    }
    if (stepTwoFormData.bci) {
      if (!stepTwoFormData.bci_name) {
        newErrors.bci_name = errorMsgName;
      }
      if (!stepTwoFormData.bci_membership_no) {
        newErrors.bci_membership_no = errorMsgMemNumber;
      }
    }
    if (
      !stepTwoFormData.icai &&
      !stepTwoFormData.icsi &&
      !stepTwoFormData.icmai &&
      !stepTwoFormData.bci
    ) {
      newErrors.icai_name = errorMsgName;
      newErrors.icai_membership_no = errorMsgMemNumber;
      newErrors.icsi_name = errorMsgName;
      newErrors.icsi_membership_no = errorMsgMemNumber;
      newErrors.icmai_name = errorMsgName;
      newErrors.icmai_membership_no = errorMsgMemNumber;
      newErrors.bci_name = errorMsgName;
      newErrors.bci_membership_no = errorMsgMemNumber;
    }
    return newErrors;
  };

  const [stepOneFormData, setStepOneFormData] = useState({
    phone: '',
    aadhar: '',
    otp: '',
    email: '',
    password: '',
  });

  const [stepTwoFormData, setStepTwoFormData] = useState({
    icai: '',
    icai_name: '',
    icai_membership_no: '',

    icsi: '',
    icsi_name: '',
    icsi_membership_no: '',

    icmai: '',
    icmai_name: '',
    icmai_membership_no: '',

    bci: '',
    bci_name: '',
    bci_membership_no: '',
    bci_certificate: '',
  });

  const handleFormChange = (e, setState, setErrorState) => {
    if (e.target.type === 'file') {
      setUploadDocsObject(e.target.files);
    }

    setState((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));

    setErrorState &&
      setErrorState((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: '',
      }));
  };

  const StepOneFormSubmit = async () => {
    setIsLoading(true);
    const newErrors = validateStep1Form();
    if (Object.keys(newErrors).length === 0) {
      try {
        const emailLowercase = stepOneFormData.email.toLowerCase();
        let createUserData = {
          emailAddress: emailLowercase,
          requestId: localStorage.getItem('otp-id'),
          password: stepOneFormData.password,
          contactNumber: stepOneFormData.phone,
          aadhaarNumber: stepOneFormData.aadhar,
          otp: stepOneFormData.otp,
        };

        const createUserResponse = await createUser(createUserData);
        if (createUserResponse?.id) {
          setCreatedUserId(createUserResponse?.id);
          setCreatedUserToken(createUserResponse?.token);
          setIsLoading(false);
          setErrorForm1(false);
          setErrorsForm1({});
          setCurrentStep(currentStep + 1);
          localStorage.setItem('user', JSON.stringify(createUserResponse));
          localStorage.setItem('id', createUserResponse?.id);
          localStorage.setItem('token', createUserResponse?.token);
          localStorage.setItem('role', createUserResponse?.role);
          gtag_report_conversion();
        } else {
          setErrorsForm1({
            aadhar: 'Aadhar Number is not correct.',
            otp: 'Incorrect OTP ',
          });
          setErrorForm1(true);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log('error while creating the user', error);
      }
    } else {
      setIsLoading(false);
      setErrorsForm1(newErrors);
    }
  };

  const form2Submit = async () => {
    const newErrors = validateStep2Form();
    if (Object.keys(newErrors).length === 0) {
      try {
        if (
          stepTwoFormData.icai ||
          stepTwoFormData.icmai ||
          stepTwoFormData.icsi ||
          stepTwoFormData.bci
        ) {
          // setCurrentStep(currentStep + 1);
          // setIsLoading(true);
          let updateUserPayload = {
            name: '',
            number: '',
            attachments: [],
          };
          if (stepTwoFormData.icai) {
            updateUserPayload.name = 'ICAI';
            updateUserPayload.number = stepTwoFormData.icai_membership_no;
            const updatedUserIcai = await updateUserProfession(
              createdUserId,
              createdUserToken,
              {
                name: updateUserPayload.name,
                number: updateUserPayload.number,
              }
            );
            console.log('icai updation successfully', updatedUserIcai);
          }
          if (stepTwoFormData.icmai) {
            updateUserPayload.name = 'ICMAI';
            updateUserPayload.number = stepTwoFormData.icmai_membership_no;
            const updatedUserIcmai = await updateUserProfession(
              createdUserId,
              createdUserToken,
              {
                name: updateUserPayload.name,
                number: updateUserPayload.number,
                attachments: [],
              }
            );
            console.log('icmai updation successfully', updatedUserIcmai);
          }
          if (stepTwoFormData.icsi) {
            updateUserPayload.name = 'ICSI';
            updateUserPayload.number = stepTwoFormData.icsi_membership_no;
            const updatedUserIcsi = await updateUserProfession(
              createdUserId,
              createdUserToken,
              {
                name: updateUserPayload.name,
                number: updateUserPayload.number,
              }
            );
            console.log('icsi updation successfully', updatedUserIcsi);
          }
          if (stepTwoFormData.bci) {
            let fileResponse;
            if (uploadDocsObject) {
              const formData = new FormData();
              formData.append('file', uploadDocsObject[0]);
              fileResponse = await uploadUserAttachment(
                formData,
                createdUserToken
              );
            }
            updateUserPayload.name = 'BCI';
            updateUserPayload.number = stepTwoFormData.bci_membership_no;
            const updatedUserBci = await updateUserProfession(
              createdUserId,
              createdUserToken,
              {
                name: updateUserPayload.name,
                number: updateUserPayload.number,
                attachments: fileResponse ? [fileResponse] : [],
              }
            );
            console.log('bci updation successfully', updatedUserBci);
          }
          setIsLoading(false);
          setShowAnimation(true);
          setShowModal(true);
          setErrorsForm2({});
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(error.message);
        setErrorDialogOpen(true);
        setShowModal(false);
      }
    } else {
      setErrorsForm2(newErrors);
    }
  };

  const handleConfirmStatus = () => {
    route('/dashboard');
    setShowAnimation(false);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    setErrorMessage('');
  };

  const stepComponents = [
    <StepOneForm
      handleChange={(e) =>
        handleFormChange(e, setStepOneFormData, setErrorsForm1)
      }
      formValue={stepOneFormData}
      onSubmit={() => StepOneFormSubmit()}
      error={errorsForm1}
    />,
    <StepTwoForm
      handleChange={(e) => handleFormChange(e, setStepTwoFormData)}
      formValue={stepTwoFormData}
      onSubmit={() => form2Submit()}
      error={errorsForm2}
    />,
  ];
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>
              <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />{' '}
              <strong>{message}</strong>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleConfirmStatus()}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div hidden={showAnimation ? true : false} className="signup-form">
        <div className="form-container">
          <div className="brand-logo">
            <img src="/images/logo.png" alt="hi"></img>
          </div>

          <Stepper
            stepComponents={stepComponents}
            onStepChange={(stepNumber) => setCurrentStep(stepNumber)}
            step={currentStep}
          />
        </div>
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default SignupForm;
