import axiosInstance from 'src/config/api';
import { POST_FILTER, postFilterColMap } from 'src/config/enums';
import {
  IComment,
  ICommentResponse,
  IPost,
  IPostResponse,
  IReply,
  Pin,
} from './post-service.interface';
import { IUser } from '../user-service/user-service.interface';

export const createPost = async (
  type: string,
  title: string,
  text: string,
  desc: string,
  selectedOption: any,
  professionalOnly: boolean,
  attachmentsArr: any,
  post?: IPost,
  publishedOn?: Date,
  notificationPriority: string = '',
  plainContent: string = '',
  notificationSource: string = ''
): Promise<IPost> => {
  let res;
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const payload = {
      content: desc,
      title,
      plainText: text,
      type,
      professionalOnly,
      attachments: attachmentsArr,
      publishedOn,
      notificationPriority,
      plainContent,
      notificationSource,
    };

    res = post
      ? (
          await axiosInstance.patch(
            `${baseUrl}/api/v1/post/${post._id}`,
            payload
          )
        ).data
      : (await axiosInstance.post(`${baseUrl}/api/v1/post`, payload)).data;

    const metaDataId: Array<string> = [];
    const subMetaDataId: Array<string> = [];

    const removeExpertise: Array<any> = [];
    const existingExpertise: Array<any> = post?.expertise || [];
    selectedOption.forEach((option: any) => {
      const data = JSON.parse(option.value);

      if (data.metaDataId) {
        if (
          !existingExpertise?.find(
            (item) => item.metaDataId === data.metaDataId
          )
        ) {
          metaDataId.push(data.metaDataId);
        }
      } else if (data.subMetaDataId) {
        if (
          !existingExpertise?.find(
            (item) => item.subMetaDataId === data.subMetaDataId
          )
        ) {
          subMetaDataId.push(data.subMetaDataId);
        }
      }
    });

    existingExpertise.forEach((expertise: any) => {
      const found = selectedOption.find((option: any) => {
        const data = JSON.parse(option.value);
        if (expertise.metaDataId) {
          return data.metaDataId === expertise.metaDataId;
        } else if (expertise.subMetaDataId) {
          return data.subMetaDataId === expertise.subMetaDataId;
        }
        return false;
      });

      if (!found) {
        removeExpertise.push(expertise._id);
      }
    });

    if (removeExpertise?.length && post) {
      const deletePush: Array<Promise<any>> = [];
      removeExpertise.forEach((id) => {
        deletePush.push(
          axiosInstance.delete(
            `${baseUrl}/api/v1/post/${post._id}/expertise/${id}`
          )
        );
      });
      await Promise.all(deletePush);
    }

    if (metaDataId.length || subMetaDataId.length) {
      await axiosInstance.post(`${baseUrl}/api/v1/post/${res._id}/expertise`, {
        metaDataId,
        subMetaDataId,
      });
    }

    return res;
  } catch (e) {
    if (res.id) {
      await axiosInstance.delete(`${baseUrl}/api/v1/post/${res.id}`);
    }
    throw new Error('failed');
  }
};

export const getAllPost = async (
  metaDataId: string,
  subMetaDataId: string,
  pageLimit: number,
  filterType: POST_FILTER,
  pageNumber: number,
  searchTerm?: string,
  view?: 'published-works' | 'questions' | 'answers' | 'pins' | 'pins-noti',
  searchType?: string,
  userId?: string,
  user?: IUser
): Promise<IPostResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const filterOn = postFilterColMap[filterType];

  const payload: any = {
    populate: '_userId',
    pageSize: pageLimit,
    pageNumber,
  };
  if (view) {
    payload._userId = userId || localStorage.getItem('id');
    if (view === 'published-works') {
      payload.type = 'WORK';
    } else if (view === 'questions') {
      payload.type = 'QUESTION';
    } else if (view === 'answers') {
      payload.view = 'comments';
    } else if (view === 'pins') {
      payload.view = 'pins';
    } else if (view === 'pins-noti') {
      payload.view = 'pins-noti';
    }
  } else {
    if (
      !subMetaDataId &&
      !metaDataId &&
      !searchType &&
      user &&
      user?.member &&
      user.member.length
    ) {
      for (const member of user.member) {
        if (member === 'BCI') {
          payload.isBCI = true;
        }
        if (member === 'ICMAI') {
          payload.isICMAI = true;
        }
        if (member === 'ICSI') {
          payload.isICSI = true;
        }
        if (member === 'ICAI') {
          payload.isICAI = true;
        }
      }
    }
  }
  if (filterOn) {
    payload.type = filterOn;
  }

  if (metaDataId.length) {
    payload['expertise.metaDataId'] = metaDataId;
  }
  if (subMetaDataId.length) {
    payload['expertise.subMetaDataId'] = subMetaDataId;
  }
  if (searchTerm) {
    //@ts-ignore
    payload.plainText = searchTerm;
  }

  return (
    await axiosInstance.get(`${baseUrl}/api/v1/post`, {
      params: payload,
    })
  ).data;
};

export const getPost = async (id: string): Promise<IPost> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/post/${id}?populate=_userId&populate=expertise`
    )
  ).data;
};
export const sendPostMemberNotification = async (
  postId: string
): Promise<IPost> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return await axiosInstance.post(
    `${baseUrl}/api/v1/post/${postId}/pushnotification`
  );
};

export const getCommentAnswers = async (
  id: string
): Promise<ICommentResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/post/${id}/comment?populate=_userId`
    )
  ).data;
};

export const getCommentReplies = async (
  postId: string,
  commentId: string,
  data?: any
): Promise<{ data: Array<IReply> }> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/reply?populate=_userId`,
      data
    )
  ).data;
};

export const fetchUpvotes = async (
  _userId: string,
  upvoteType: string,
  ids: string[]
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  let upvoteStr = '';
  ids?.forEach((id) => {
    upvoteStr = upvoteStr + `&${upvoteType}=${id}`;
  });

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/up-vote?_userId=${_userId}${upvoteStr}`
    )
  ).data;
};

export const getPostUpvotes = async (
  postId: string,
  data?: any
): Promise<{ data: Array<IReply> }> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/post/${postId}/upvote?populate=_userId`,
      data
    )
  ).data;
};

export const getCommentUpvotes = async (
  postId: string,
  commentId: string,
  data?: any
): Promise<{ data: Array<IReply> }> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/upvote?populate=_userId`,
      data
    )
  ).data;
};

export const getCommentReplyUpvotes = async (
  postId: string,
  commentId: string,
  replyId: string,
  data?: any
): Promise<{ data: Array<IReply> }> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/reply/${replyId}/upvote?populate=_userId`,
      data
    )
  ).data;
};

export const postPostUpvote = async (postId: string): Promise<IReply> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.post(`${baseUrl}/api/v1/post/${postId}/upvote`))
    .data;
};

export const postCommentUpvote = async (
  postId: string,
  commentId: string
): Promise<IReply> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.post(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/upvote`
    )
  ).data;
};

export const deletePostUpvote = async (
  postId: string,
  userId: string
): Promise<IComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(
      `${baseUrl}/api/v1/post/${postId}/upvote/${userId}`
    )
  ).data;
};

export const deleteCommentUpvote = async (
  postId: string,
  commentId: string,
  userId: string
): Promise<IComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/upvote/${userId}`
    )
  ).data;
};

export const postCommentReplyUpvote = async (
  postId: string,
  commentId: string,
  replyId: string
): Promise<IReply> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.post(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/reply/${replyId}/upvote`
    )
  ).data;
};

export const deleteCommentReplyUpvote = async (
  postId: string,
  commentId: string,
  replyId: string,
  userId: string
): Promise<IComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/reply/${replyId}/upvote/${userId}`
    )
  ).data;
};

export const getAllPostWithSearch = async (
  searchTerm: string
): Promise<IPost[]> => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  return (
    await axiosInstance.get(`${baseUrl}/api/v1/post`, {
      params: { plainText: searchTerm, populate: '_userId' },
    })
  ).data.data;
};

export const postAnswer = async (
  id: string,
  content: string,
  attachments: any[],
  comment?: IComment
): Promise<IComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  if (comment?.id) {
    return (
      await axiosInstance.patch(
        `${baseUrl}/api/v1/post/${id}/comment/${comment._id}`,
        {
          content,
          attachments,
        }
      )
    ).data;
  } else {
    return (
      await axiosInstance.post(`${baseUrl}/api/v1/post/${id}/comment`, {
        content,
        attachments,
      })
    ).data;
  }
};

export const postReply = async (
  quesId: string,
  commId: string,
  content: string,
  attachments: any[],
  reply?: IReply
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  if (reply?.id) {
    return (
      await axiosInstance.patch(
        `${baseUrl}/api/v1/post/${quesId}/comment/${commId}/reply/${reply.id}`,
        {
          content: content || '',
          attachments,
        }
      )
    ).data;
  } else {
    return (
      await axiosInstance.post(
        `${baseUrl}/api/v1/post/${quesId}/comment/${commId}/reply`,
        {
          content: content || '',
          attachments,
        }
      )
    ).data;
  }
};

export const deleteCommentReply = async (
  postId: string,
  commentId: string,
  replyId: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}/reply/${replyId}`
    )
  ).data;
};

export const deleteComment = async (postId: string, commentId: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(
      `${baseUrl}/api/v1/post/${postId}/comment/${commentId}`
    )
  ).data;
};

export const deletePost = async (postId: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.delete(`${baseUrl}/api/v1/post/${postId}`)).data;
};

export const pinQues = async (
  userId: string,
  postId: string,
  type = Pin.POST
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return axiosInstance.post(`${baseUrl}/api/v1/user/${userId}/pin`, {
    type,
    postId,
  });
};

export const deletePinQues = async (
  userId: string,
  postId: string,
  type?: string
): Promise<any> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return axiosInstance.delete(
    `${baseUrl}/api/v1/user/${userId}/pin/${postId}/${type}`
  );
};

export const postReportPost = async (postId: string, description: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/report`, {
      postId,
      description,
    });
    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
};

export const fetchAllPostUpvotes = async (postIds: any, type: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return await axiosInstance.post(`${baseUrl}/api/v1/post/allpost/upvotes`, {
    postIds,
    type,
  });
};

export const fetchAllPostPins = async (postIds: any, userId: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return await axiosInstance.post(`${baseUrl}/api/v1/post/allpost/pins`, {
    postIds,
    userId,
  });
};

export const fetchPostPin = async (postId: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  const response = await axiosInstance.get(
    `${baseUrl}/api/v1/post/${postId}/pin`
  );
  return response.data;
};
