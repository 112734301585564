import React from 'react';

interface BottomBarProfImageProps {
  fill: string;
}

const BottomBarProfImage: React.FC<BottomBarProfImageProps> = ({ fill }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="500.000000pt"
      height="500.000000pt"
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M2205 4978 c-212 -24 -475 -95 -695 -190 -811 -347 -1393 -1132
   -1489 -2010 -14 -124 -14 -416 0 -549 22 -224 94 -493 191 -719 328 -765 1039
   -1322 1875 -1470 121 -21 414 -36 552 -29 344 20 636 98 966 259 751 366 1256
   1076 1370 1925 19 142 22 415 5 565 -43 397 -186 792 -406 1120 -415 620
   -1058 1012 -1804 1100 -101 12 -453 11 -565 -2z m555 -302 c418 -52 823 -225
   1130 -481 105 -88 285 -275 357 -370 209 -278 350 -603 414 -953 26 -143 36
   -451 20 -603 -48 -441 -237 -878 -518 -1196 l-28 -32 -79 49 c-101 63 -250
   125 -451 189 -236 74 -449 142 -460 147 -6 2 -9 -39 -7 -118 5 -157 -12 -238
   -76 -371 -107 -223 -267 -442 -420 -573 l-64 -54 -78 0 -79 0 -70 61 c-145
   125 -295 328 -402 544 -74 151 -92 227 -87 388 2 64 -1 117 -5 117 -5 0 -75
   -21 -155 -46 -81 -25 -212 -66 -292 -91 -245 -76 -390 -139 -522 -226 l-26
   -18 -81 103 c-231 292 -384 637 -446 1008 -26 151 -31 458 -10 620 32 253 105
   492 221 722 115 225 234 387 420 570 128 125 191 177 320 263 282 189 616 311
   969 354 114 14 382 12 505 -3z"
        />
        <path
          d="M2275 4194 c-236 -42 -431 -144 -575 -302 -168 -183 -221 -456 -165
   -857 7 -49 11 -93 8 -97 -2 -5 -17 -8 -32 -8 -48 0 -87 -57 -97 -141 -12 -111
   42 -273 121 -359 23 -25 60 -85 83 -134 143 -302 334 -531 532 -639 234 -128
   544 -111 770 43 164 111 352 357 467 608 19 42 52 95 73 117 53 55 95 145 116
   246 14 71 15 93 5 142 -15 71 -51 117 -91 117 -16 0 -31 3 -33 8 -2 4 2 52 10
   107 18 128 20 401 4 480 -45 219 -131 358 -301 485 -123 91 -276 154 -454 185
   -110 19 -332 18 -441 -1z m-133 -640 c278 -248 540 -347 1027 -386 l154 -12
   -6 -86 c-4 -47 -18 -141 -32 -209 -14 -68 -25 -127 -25 -130 1 -3 12 17 26 45
   51 107 100 130 149 72 26 -31 27 -37 23 -113 -6 -96 -35 -167 -99 -239 -28
   -31 -72 -108 -122 -210 -97 -199 -185 -332 -274 -417 -83 -78 -135 -109 -239
   -145 -70 -24 -94 -27 -214 -28 -154 -1 -211 10 -318 62 -157 76 -285 233 -430
   529 -51 105 -96 180 -126 215 -59 68 -88 142 -94 239 -4 70 -2 77 23 107 49
   58 96 36 149 -71 15 -29 24 -41 22 -27 -46 236 -56 312 -56 412 0 105 1 113
   33 171 75 142 225 257 359 276 4 1 35 -24 70 -55z"
        />
        <path
          d="M2327 1365 c-70 -53 -75 -69 -40 -118 15 -23 52 -59 80 -82 42 -32
   51 -45 47 -63 -2 -12 -38 -105 -80 -205 -63 -153 -73 -185 -65 -205 17 -37
   101 -121 169 -169 l63 -45 41 28 c53 37 185 169 193 193 3 10 -27 97 -75 211
   -44 107 -80 198 -80 203 0 6 27 34 60 64 33 30 69 68 81 84 20 28 20 31 5 55
   -9 13 -35 38 -58 54 -40 30 -43 30 -168 30 l-127 0 -46 -35z"
        />
      </g>
    </svg>
  );
};

export default BottomBarProfImage;
