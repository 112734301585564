import { Link, useNavigate } from 'react-router-dom';
import styles from './baseSignUp.module.css';
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import LoginBtns from '../login-btns';
import { Helmet } from 'react-helmet-async';

const BaseSignUp = () => {
  const navigate = useNavigate();

  const newSignUp = () => {
    navigate('/newsignup');
  };

  const navigateToHome = () => {
    navigate('/dashboard');
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>White Collar Professional | Sign-Up</title>
        <meta
          name="description"
          content="White Collar Professional - WCP Community Platform. Sign-Up now! Let's connect, collaborate and elevate professional journey."
        />

        <link rel="canonical" href="/basesignup" />
      </Helmet>

      <div className={styles['parent-basesignup']}>
        <div className={styles['basesignup-navbar-wrapper']}>
          <div className={styles['basesignup-navbar']}>
            <Button color="transparent" onClick={() => navigateToHome()}>
              Home
            </Button>
            <Button color="transparent">
              <LoginBtns
                signin="Sign In"
                textDecoration
                color
                fontSize={isMobile ? '16px' : '20px'}
                fontWeight
              />
            </Button>
          </div>
        </div>
        <div className={styles['basesignup-form']}>
          <div className={styles['form-container']}>
            <Link to="/" className={styles['header-wrapper']}>
              <div className={styles['header-title']}>
                <img src="/images/logo.png" alt="hi"></img>
              </div>
              <div className={styles['header-subtitle']}>
                {isMobile ? (
                  <u>
                    Welcome ! Just a step away from joining <br />
                    WCP-Community Platform.
                  </u>
                ) : (
                  <u>
                    Welcome ! Just a step away from joining WCP-Community
                    Platform.
                  </u>
                )}
              </div>
            </Link>
            <div className={styles['btn-wrapper']}>
              <div className={styles['btn-wrapper']}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/images/signupGoogle.png"
                    alt="googleSigninLogo"
                    className={styles['signinGoogle-img']}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_HOST}/api/v1/auth/google`,
                        '_self'
                      )
                    }
                  />
                </div>{' '}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: 'rgb(115, 115, 115, 1)',
                    margin: '20px auto 20px auto',
                    width: '75%',
                  }}
                >
                  <div
                    style={{
                      flexGrow: '1',
                      height: '1px',
                      backgroundColor: 'rgb(219, 219, 219)',
                    }}
                  ></div>
                  <div
                    style={{
                      padding: '0 10px',
                      fontSize: '10px',
                      fontFamily: 'Roboto',
                    }}
                  >
                    OR
                  </div>
                  <div
                    style={{
                      flexGrow: '1',
                      height: '1px',
                      backgroundColor: 'rgb(219, 219, 219)',
                    }}
                  ></div>
                </div>
                <div>
                  <span
                    onClick={() => newSignUp()}
                    style={{ cursor: 'pointer' }}
                  >
                    Create Account
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['alreadylogin-wrapper']}>
              Already Registered ?
              <LoginBtns
                signin="Login"
                textDecoration="underline"
                color="blue"
                fontSize={isMobile ? '14px' : '18px'}
                fontWeight
              />
            </div>
            <div className={styles['signup-later']}>
              Sign-Up Later &gt;&gt; &nbsp;
              <Link to={'/dashboard'} style={{ color: '#0000ff' }}>
                Get Started
              </Link>
            </div>
          </div>
          <div className={styles['basesignup-footer']}>
            {isMobile
              ? `Copyright © 2024 - Taaran Industries Private Limited`
              : `Copyright © 2024 White Collar Professional - Taaran Industries Private Limited`}
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseSignUp;
