import React from 'react';
import AvatarRow from '../avatar-row/avatar-row';
import { formatDistanceToNow, isValid } from 'date-fns';
import { IUser } from 'src/services/user-service/user-service.interface';
import styles from './trend.module.css';
import TrendActions from './TrendActions';

export default function TrendCommentListItem({ key, item }: any) {
  const loggedInUser = localStorage.getItem('id') || '';
  const getLapsedTime = (createdOn: string) => {
    const createdOnDate = new Date(createdOn);
    if (isValid(createdOnDate)) {
      return formatDistanceToNow(createdOnDate, { addSuffix: true });
    } else {
      return 'Invalid date';
    }
  };
  return (
    <div key={key} className={styles.commentContainer}>
      <div className={styles.commentHeader}>
        <AvatarRow showMembership data={item._userId as unknown as IUser} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '36px',
          }}
        >
          <div style={{ width: 'max-content', fontSize: '12px' }}>
            {getLapsedTime(item.createdOn)}
          </div>
          {/* @ts-ignore */}
          {loggedInUser === item._userId._id && (
            <TrendActions trendId={item?._id} type="trendComment" />
          )}
        </div>
      </div>

      <div
        className={styles.commentContent}
        style={{ fontSize: '13px', marginLeft: '4px' }}
      >
        <p dangerouslySetInnerHTML={{ __html: item.content }} />
      </div>
    </div>
  );
}
