import { IOptions } from 'src/components/create-post/create-post.helper';
import { IMetaData } from 'src/services/meta-data-service/meta-data-interface';
import { IItem } from 'src/services/user-service/user-service.interface';

export const formatExpertiseOptions = (
  expertises: IItem[],
  menu: IMetaData[]
): IOptions[] => {
  const selectedOptions: IOptions[] = [];

  menu.forEach((metaData) => {
    const isMetaDataPresent = expertises.some(
      (expertise) => expertise.metaDataId === metaData._id
    );
    if (isMetaDataPresent) {
      selectedOptions.push({
        label: metaData.name,
        value: JSON.stringify({ metaDataId: metaData._id }),
        _id: metaData._id,
      });
    }
    metaData.subMetaDatas?.forEach((subMetaData) => {
      const isSubMetaDataPresent = expertises.some(
        (expertise) => expertise.subMetaDataId === subMetaData._id
      );
      if (isSubMetaDataPresent) {
        selectedOptions.push({
          label: subMetaData.name,
          value: JSON.stringify({ subMetaDataId: subMetaData._id }),
          _id: subMetaData._id,
        });
      }
    });
  });

  return selectedOptions;
};
