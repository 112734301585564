import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Select, { MultiValue } from 'react-select';

import styles from './profile.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import {
  IOptions,
  formatMenuToOptions,
} from 'src/components/create-post/create-post.helper';
import { MenuContext } from 'src/context/menu.context';
import { UserContext } from 'src/context/user.context';
import { IItem, IUser } from 'src/services/user-service/user-service.interface';
import {
  deleteUserExpertise,
  fetchUserExpertise,
  saveUserExpertise,
} from 'src/services/user-service/user-servicec';
import { formatExpertiseOptions } from './profile.helper';

interface IExpertiseProps {
  user: IUser;
}

const Expertise = ({ user }: IExpertiseProps) => {
  const { id } = useContext(UserContext);
  const { menu } = useContext(MenuContext);
  const selectedOptionRef = useRef<IOptions[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOptions[]>([]);
  const [expertise, setExpertise] = useState<IItem[]>([]);
  const [loadingExpertise, setLoadingExpertise] = useState(false);

  useEffect(() => {
    setSelectedOption(selectedOptionRef.current);
  }, [selectedOptionRef.current]);

  const getUserExpertise = async (profileId: string) => {
    setLoadingExpertise(true);
    const expertises = (await fetchUserExpertise(profileId)).data;
    selectedOptionRef.current = formatExpertiseOptions(expertises, menu);
    setSelectedOption(selectedOptionRef.current);
    setExpertise(expertises);
    setLoadingExpertise(false);
  };

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const profileId = query.get('profileId');
  const loggedinUserId = localStorage.getItem('id');
  useEffect(() => {
    if (profileId && menu.length) {
      getUserExpertise(profileId);
    }
  }, [profileId, menu]);

  function handleExpertiseChange(options: MultiValue<IOptions | null>): void {
    if (options) {
      selectedOptionRef.current = options as unknown as IOptions[];
      setSelectedOption(selectedOptionRef.current);
    }
  }

  const handleBlur = async (): Promise<void> => {
    const addedItems = selectedOptionRef.current.filter(
      (option) =>
        !expertise.some(
          (prevOption) =>
            prevOption.metaDataId === option._id ||
            prevOption.subMetaDataId === option._id
        )
    );

    const removedItems = expertise.filter(
      (expertise) =>
        !selectedOptionRef.current.some(
          (item) =>
            item._id === expertise.metaDataId ||
            item._id === expertise.subMetaDataId
        )
    );

    const removedExpertiseIds = removedItems
      .map((expertise) => expertise._id || '')
      .filter((item) => item);

    const addExpertiseIds = addedItems.map((expertise) => expertise.value);
    setLoadingExpertise(true);
    await saveUserExpertise(user._id, addExpertiseIds);
    await deleteUserExpertise(user._id, removedExpertiseIds);
    if (profileId) {
      getUserExpertise(profileId);
    }
    setLoadingExpertise(false);
  };

  return (
    <div className={styles['expertise-container']}>
      <h2 className={styles['container-heading']}>
        Expertise&nbsp;
        {loadingExpertise && (
          <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
        )}
      </h2>
      <div className={styles['select-container']}>
        <Select
          defaultValue={selectedOption}
          onChange={(options) => handleExpertiseChange(options)}
          onBlur={handleBlur}
          options={formatMenuToOptions(menu)}
          isMulti
          value={selectedOption}
          isDisabled={id !== user?._id || loadingExpertise}
          styles={{
            multiValue: (base) => ({
              ...base,
              padding: '4px 8px 4px 16px',
              borderRadius: '23px',
              color: '#1a1a1a',
              textAlign: 'center',
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '14.41px',
            }),
            control: (base) => ({
              ...base,
              borderRadius: '12px',
              padding: '10px 14px',
            }),
            placeholder: (base) => ({
              ...base,
              fontSize: '14px',
            }),
          }}
          components={{
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
          placeholder={
            loggedinUserId !== profileId ? 'N/A Expertise' : 'Select Expertise'
          }
        />
        {profileId === loggedinUserId && (
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id={`tooltip`} className={styles.tooltipOverlay}>
                Expertise are nonetheless, but Signatures eg: Income-Tax, GST,
                Civil & Criminal Law ...etc. “<strong>+ Create Category</strong>
                ” to add new Tag.
              </Tooltip>
            }
          >
            <img
              src="/images/helpIcon.png"
              className={styles['help-icon']}
              style={{ marginTop: '12px' }}
              alt="help icon"
            />
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
};

export default Expertise;
