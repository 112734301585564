import { LoaderProvider } from 'src/context/loader.context';
import { MenuProvider } from 'src/context/menu.context';
import { StorageProvider } from 'src/context/storage.context';
import { UserProvider } from 'src/context/user.context';
import { MessageProvider } from 'src/context/message.context';
import Message from 'src/pages/message';
const WithContext = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <LoaderProvider>
        <StorageProvider>
          <MenuProvider>
            <MessageProvider>
              {<Message />}
              <UserProvider>{children}</UserProvider>
            </MessageProvider>
          </MenuProvider>
        </StorageProvider>
      </LoaderProvider>
    </>
  );
};

export default WithContext;
