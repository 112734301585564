import { useEffect, useState } from 'react';
import styles from './notification.module.css';
import {
  IFormattedNotifications,
  formatNotifications,
  margeFormattedNotifications,
} from './notification.helper';

import NoData from 'src/components/no-data';
import { LoadingLottie } from 'src/components/lotties/loading';
import Modal from 'react-bootstrap/Modal';
import SignupModal from 'src/components/stepper/SignupModal';
import PinButton from 'src/components/pin-button';
import { IPost, Pin } from 'src/services/post-service/post-service.interface';
import { INotification } from 'src/services/notification-service/notification-interface';
import {
  fetchUpdatePin,
  getUpdates,
} from 'src/services/update-service/update-service';
import { useSearchParams } from 'react-router-dom';

const DashboardNotification = () => {
  const [loading, setLoading] = useState(true);
  const [signupModal, setSignupModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newSearch, setNewSearch] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDate, setStartDate] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endDate, setEndDate] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refresh, setRefresh] = useState<number>(0);
  const [dummyTrigger, setDummyTrigger] = useState<number>(1);
  const token = localStorage.getItem('token');
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const metaDataId = searchParams.get('metaDataId') || '';
  const subMetaDataId = searchParams.get('subMetaDataId') || '';
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const [formattedNotifications, setFormattedNotifications] =
    useState<IFormattedNotifications>({});
  const [notificationCompleted, setNotificationCompleted] =
    useState<boolean>(false);
  const view = searchParams.get('view') || '';
  const loggedInUserId = localStorage.getItem('id') || '';
  const fetchNotifications = async (
    pageNumber: number,
    startDate: string,
    endDate: string,
    metaDataId: string,
    subMetaDataId: string
  ) => {
    setLoading(true);
    const response = await getUpdates(
      pageNumber,
      100,
      startDate,
      endDate,
      user,
      'high',
      view,
      metaDataId,
      subMetaDataId
    );
    if (!response.data.length) {
      setNotificationCompleted(true);
    }

    const notification = response.data;

    const formatRes = formatNotifications(notification);
    setFormattedNotifications(
      margeFormattedNotifications(formattedNotifications, formatRes)
    );
    setLoading(false);
    setNewSearch(false);
  };

  useEffect(() => {
    setPageNumber(1);
    setNotificationCompleted(false);
    setNewSearch(true);
    setFormattedNotifications({});
    setDummyTrigger((num) => num + 1);
  }, [metaDataId, refresh]);

  useEffect(() => {
    if (notificationCompleted) {
      return;
    }
    fetchNotifications(
      pageNumber,
      startDate,
      endDate,
      metaDataId,
      subMetaDataId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dummyTrigger]);

  const [modalData, setModalData] = useState({
    title: '',
    plainContent: '',
    contentUrl: '',
    notificationSource: '',
    userId: '',
    notiObj: {} as INotification,
    pinId: false,
  });

  const handleNotificationClick = async (notiObj: any) => {
    const res = (await fetchUpdatePin(notiObj?._id, loggedInUserId)).data;
    const hasPins = res?.data[0]?._id?.length ? true : false;
    setModalData({
      title: notiObj.title,
      plainContent: notiObj.content,
      contentUrl: notiObj.notiLink,
      notificationSource: notiObj.notificationSource,
      userId: notiObj.userId,
      notiObj: notiObj,
      pinId: hasPins,
    });

    setShowNotificationModal(true);
  };

  const highPriorityNotifications = Object.values(formattedNotifications)
    .flat()
    .sort(
      (a, b) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
    )
    .slice(0, 10);

  return (
    <>
      {loading ? (
        <LoadingLottie height="400px" />
      ) : (
        highPriorityNotifications.map((notiObj) => (
          <div
            key={notiObj.title + notiObj.createdOn}
            className={styles['noti-date-ul']}
            style={{ marginBottom: '5px' }}
          >
            <div className={styles['noti-row']}>
              <div className={styles['noti-wrapper']}>
                <div className={styles['noti-content-ul']}>
                  <div className={styles['noti-title']} style={{ padding: 0 }}>
                    <li className={styles['dashboard-noti-li']}>
                      {notiObj?.notificationSource === '' ? (
                        <>
                          {token ? (
                            <a
                              style={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}
                              href={notiObj?.content}
                            >
                              {notiObj.title}
                            </a>
                          ) : (
                            <span
                              style={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}
                              onClick={() => setSignupModal(true)}
                            >
                              {notiObj.title}
                            </span>
                          )}
                        </>
                      ) : (
                        <span
                          style={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                          onClick={() =>
                            !token
                              ? setSignupModal(true)
                              : handleNotificationClick(notiObj)
                          }
                        >
                          {notiObj.title}
                        </span>
                      )}
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {notificationCompleted && <NoData />}

      <Modal
        show={showNotificationModal}
        onHide={() => {
          setShowNotificationModal(false);
        }}
        centered
        size="lg"
        className={styles['noti-modal']}
      >
        <Modal.Header closeButton className={styles['noti-modal-header']}>
          <Modal.Title className={styles['noti-modal-header-title']}>
            {modalData.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className={styles['noti-desc-modal']}
            dangerouslySetInnerHTML={{
              __html: modalData.plainContent,
            }}
          />
        </Modal.Body>
        <Modal.Footer className={styles['noti-modal-footer']}>
          <a
            href={modalData.contentUrl}
            target="_blank"
            rel="noreferrer"
            style={{
              margin: '0 auto 0 0',
              textDecoration: 'none',
              fontSize: '12px',
            }}
          >
            <span
              style={{
                textDecoration: 'none',
                color: '#000',
              }}
            >
              Source:
            </span>{' '}
            {modalData.notificationSource}
          </a>
          <PinButton
            self={modalData.notiObj._userId === loggedInUserId}
            pinId={modalData?.pinId}
            selectedQues={modalData.notiObj as unknown as IPost}
            type={Pin.UPDATE}
          />
        </Modal.Footer>
      </Modal>
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default DashboardNotification;
