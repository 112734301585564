import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './navbar.module.css';
import { debounce } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SEARCH_TYPE } from 'src/config/enums';
import { getAllPostWithSearch } from 'src/services/post-service/post-service';
import { fetchUsersbyMatch } from 'src/services/user-service/user-servicec';
import {
  IOption,
  formatPostToOption,
  formatUserToOption,
} from './search.helper';
import SignupModal from '../stepper/SignupModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';

const SearchBar = ({
  searchQuery,
  searchSearchQuery,
  isSearchBarQuery,
  setIsSearchBarQuery,
}: any) => {
  const router = useNavigate();
  const [searchParams] = useSearchParams();
  const currentUrl = window.location.href;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchType, setSearchType] = useState(
    currentUrl.includes('searchType=Professional')
      ? SEARCH_TYPE.PROFESSIONAL
      : SEARCH_TYPE.QUESTION
  );
  useEffect(() => {
    setSearchType(
      currentUrl.includes('searchType=Professional') ||
        currentUrl.includes('profile')
        ? SEARCH_TYPE.PROFESSIONAL
        : SEARCH_TYPE.QUESTION
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  const [options, setOptions] = useState<IOption[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleCloseDropdown = () => {
    if (ref.current) {
      setIsSearchBarQuery(false);
      setOptions([]);
    }
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleCloseDropdown();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsSearchBarQuery]);
  const loadOptions = async (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    let res;

    if (searchType === SEARCH_TYPE.QUESTION) {
      res = formatPostToOption(await getAllPostWithSearch(searchTerm));
    } else {
      res = formatUserToOption((await fetchUsersbyMatch(searchTerm)).data);
    }

    setOptions(res);
  };

  //TODO: implement debounce
  const debouncedSearchOptions = debounce(loadOptions, 500);

  const handleSearch = (events: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      searchQuery.length > 0 &&
      (events.key === 'Enter' || events.key === 'Return')
    ) {
      events.preventDefault();
      const searchParams = new URLSearchParams({ searchType, searchQuery });
      router(`/search?${searchParams.toString()}`);
      setOptions([]);
      handleCloseDropdown();
    }
  };
  const handleQueryClear = () => {
    searchSearchQuery('');
    if (currentUrl.includes('/search?searchType=Professional')) {
      router('/search?searchType=Professional');
    } else if (currentUrl.includes('/search?searchType=Question')) {
      router('/dashboard');
    }
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchSearchQuery(e.target.value || '');

    if (e.target.value) {
      //@ts-ignore
      debouncedSearchOptions(e);
    }
  };
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  //@ts-ignore
  const handleOptionClick = (option: IOption) => {
    const searchParams =
      searchType === SEARCH_TYPE.QUESTION
        ? new URLSearchParams({ postId: option.value })
        : new URLSearchParams({ profileId: option.value });
    searchType === SEARCH_TYPE.QUESTION
      ? !token
        ? setSignupModal(true)
        : router(`/post?${searchParams.toString()}`)
      : !token
      ? setSignupModal(true)
      : router(`/profile?${searchParams.toString()}`);
    searchSearchQuery('');
    setOptions([]);
    handleCloseDropdown();
  };

  return (
    <div ref={ref}>
      <div
        className={classNames(
          window.innerWidth > 700 && isSearchBarQuery
            ? styles['wc-search-container-active']
            : window.innerWidth > 700 && !isSearchBarQuery
            ? styles['wc-search-container']
            : styles['wc-search-container']
        )}
      >
        <div
          className={classNames(
            styles['wc-search'],
            isSearchBarQuery && styles['wc-search-paddingActive'],
            options.length > 0 && isSearchBarQuery && styles['wc-search-active']
          )}
        >
          {window.innerWidth < 700 ? (
            <div className={styles['search-user-img-mobile']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                width={16}
                height={19}
              />
            </div>
          ) : (
            <div className={styles['search-user-img-windows']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                height={20}
              />
            </div>
          )}

          {window.innerWidth > 700 ? (
            <input
              ref={inputRef}
              type="text"
              className={styles['wc-search-input']}
              placeholder={
                currentUrl.includes('search?searchType=Professional') ||
                currentUrl.includes('profile')
                  ? ' Search Professional  [ Type//: Name ]'
                  : ' Search Query / Insight  [ Type//: keyword, keyphrase ]'
              }
              value={searchQuery}
              onChange={(e) => handleInputChange(e)}
              onFocus={() => {
                setIsSearchBarQuery(true);
              }}
              onKeyDown={handleSearch}
              enterKeyHint="go"
            />
          ) : (
            <input
              ref={inputRef}
              type="text"
              className={styles['wc-search-input']}
              placeholder={
                currentUrl.includes('search?searchType=Professional') ||
                currentUrl.includes('profile')
                  ? ' Search Professional  '
                  : ' Search Query / Insight  '
              }
              value={searchQuery}
              onChange={(e) => handleInputChange(e)}
              onFocus={() => {
                setIsSearchBarQuery(true);
              }}
              onKeyDown={handleSearch}
              enterKeyHint="go"
            />
          )}
          {searchQuery.length > 0 && (
            <FontAwesomeIcon
              icon={faCircleXmark}
              onClick={() => {
                handleQueryClear();
              }}
            />
          )}
        </div>
        {isSearchBarQuery && options.length > 0 && (
          <Dropdown.Menu
            show
            className={styles.navbarSeacrhDropdown}
            style={{
              width: ref?.current?.offsetWidth,
              maxWidth: ref?.current?.offsetWidth,
            }}
          >
            {options.map((option) => (
              <Dropdown.Item
                style={{
                  padding: '8px 12px ',
                  borderBottom: '1px solid #ccc',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                key={option.value}
                className={styles['dropdown-option']}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
        <SignupModal
          signupModal={signupModal}
          setSignupModal={setSignupModal}
        />
      </div>
    </div>
  );
};

export default SearchBar;
