import { Button, Modal, ModalBody } from 'reactstrap';

import classNames from 'classnames';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'src/context/user.context';
import { updateUser } from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import { SuccessLottie } from '../lotties/success';
import styles from './navbar.module.css';
type ICreatePostProps = {
  show: boolean;
  onHide: () => void;
};

export default function DeleteAccount(props: ICreatePostProps) {
  const { id } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const router = useNavigate();

  async function handleDelete(event: any): Promise<void> {
    setLoading(true);
    await updateUser(id, { status: 'DELETE' });

    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
      localStorage.clear();
      router('/');
    }, 2500);
  }

  return (
    <Modal
      returnFocusAfterClose={true}
      isOpen={props.show}
      toggle={() => props.onHide()}
      className={styles.deleteAccountModal}
    >
      <ModalBody style={{ padding: '32px' }}>
        {success && <SuccessLottie height={410} />}
        {loading && <LoadingLottie height={410} />}
        {!success && !loading && (
          <>
            <h5 style={{ textAlign: 'center' }}>
              Are you sure you want to <br />
              permanently delete your account ?
            </h5>
            <br />
            <div className={styles.actionButtonContainer}>
              <Button
                onClick={() => props.onHide()}
                outline
                className={classNames(
                  styles.actionButton,
                  styles.actionButtonPrimary
                )}
              >
                Cancel
              </Button>
              <span className={styles.actionButtonGap} />
              <Button onClick={handleDelete} className={styles.actionButton}>
                Confirm
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
