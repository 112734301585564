import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { memo } from 'react';

const Root = memo(() => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
});

export default Root;
