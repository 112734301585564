import React, { useContext, useState } from 'react';
import { MessageContext } from 'src/context/message.context';
import { resolveBEAsset } from 'src/utils/urls';

const ChatList = ({ refreshList }: any) => {
  const { openChatWindow } = useContext(MessageContext);
  const [searchTerm, setSearchTerm] = useState('');

  const { _id } = JSON.parse(localStorage.getItem('user') || '{}');

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filteredChats = refreshList?.filter((chat: any) =>
    chat?.user?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatMessage = (content: any) => {
    if (typeof content === 'object') {
      if (content?.type === 'text') {
        return content?.content;
      } else if (content?.type === 'link') {
        return content?.content;
      }
    } else {
      return content;
    }
    return content;
  };

  return (
    <div className="chat-list">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      <ul>
        {filteredChats.map((chat: any) => (
          <li
            key={chat?.user?._id}
            onClick={() => {
              openChatWindow(chat);
            }}
          >
            <span style={{ cursor: 'pointer' }}>
              <img
                src={
                  chat?.user?.picture?.path
                    ? resolveBEAsset(chat?.user?.picture?.path || '')
                    : '/images/defaultProfilePic.svg'
                }
                style={{
                  height: '33px',
                  width: '33px',
                  borderRadius: '50%',
                  border: '1px solid #e6e6e6',
                }}
                alt={`Profile of ${chat?.user?.name}`}
              />
              <div className="chat-details">
                <h3 style={{ marginBottom: '4px' }}>
                  {chat?.user?.name} {''}
                  {chat?.user?.isProfessional && (
                    <img
                      src="/images/prof-verification.png"
                      alt="VerifiedLogo"
                      style={{
                        height: '15px',
                        width: '15px',
                      }}
                    />
                  )}
                </h3>
                <p>
                  <span className="chat-details-own">
                    {chat?.message?._userId === _id ? 'You: ' : ''}
                  </span>
                  {formatMessage(chat?.message?.content)}
                </p>
              </div>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatList;
