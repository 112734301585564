import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchUser } from 'src/services/user-service/user-servicec';
import styles from './callback.module.css';

export default function Callback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getUser = useCallback(
    async (userId: string, token: string) => {
      if (userId) {
        const user = await fetchUser({ _id: userId });
        localStorage.setItem('id', userId);
        localStorage.setItem(
          'user',
          JSON.stringify({ ...user?.data[0], token })
        );
        localStorage.setItem('role', user.data[0].role);
        // Redirect to protected page or any other route
        navigate('/dashboard');
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        navigate('/');
      }
    },
    [navigate]
  );

  useEffect(() => {
    // Extract token from URL params
    const token = searchParams.get('token');
    const urlUserId = searchParams.get('userId');

    // Save token to local storage or state
    localStorage.setItem('token', token || '');

    getUser(urlUserId || '', token || '');
  }, [getUser, searchParams]);

  return (
    <div>
      <div className={styles['preloader-plus']}>
        <img
          src="/images/wc_logoMain.svg"
          className={styles['preloader-custom-img']}
          alt="wcp-logo"
        />
      </div>
    </div>
  );
}
