import React, { createContext, useContext, useState, ReactNode } from 'react';
import { LoadingLottie } from 'src/components/lotties/loading';

interface LoaderContextValue {
  isLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextValue | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  return (
    <LoaderContext.Provider value={{ isLoading, showLoader, hideLoader }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            height: '100vh',
            width: '100vw',
            zIndex: '150',
          }}
        >
          <LoadingLottie />
        </div>
      )}

      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
