import { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import styles from '../../sidebar/sidebar.module.css';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { resolveBEAsset } from 'src/utils/urls';
import { IMenuList } from 'src/components/sidebar/sidebar.interface';
import { MenuContext } from 'src/context/menu.context';
import { formatMenu } from 'src/components/sidebar/sidebar.helper';

const ExpertiseFilterProfessional = () => {
  const [selectedMetaDataId, setSelectedMetaDataId] = useState<string[]>([]);
  const [menuList, setMenuList] = useState<IMenuList[]>([]);
  const { menu } = useContext(MenuContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isFilterDropdownShow, setIsFilterDropdownShow] = useState(true);

  const handleFilterDropdownShow = () => {
    if (window.innerWidth < 700) {
      return;
    }
    setIsFilterDropdownShow((prev) => !prev);
  };

  const handleMenuSelect = (id: string): void => {
    const urlParams = new URLSearchParams(searchParams.toString());
    const existingIds = urlParams.get('profMetaDataId')?.split(',') || [];

    if (existingIds.includes(id)) {
      const updatedIds = existingIds.filter(
        (existingId: string) => existingId !== id
      );
      if (updatedIds.length > 0) {
        urlParams.set('profMetaDataId', updatedIds.join(','));
      } else {
        urlParams.delete('profMetaDataId');
      }
    } else {
      existingIds.push(id);
      urlParams.set('profMetaDataId', existingIds.join(','));
    }

    // navigate(`/search?${urlParams.toString()}`);

    navigate({
      search: createSearchParams(urlParams).toString(),
    });
    setSelectedMetaDataId(existingIds);
  };

  useEffect(() => {
    const metaDataParam = searchParams.get('profMetaDataId') || '';
    setSelectedMetaDataId(metaDataParam.split(','));
  }, [searchParams]);

  useEffect(() => {
    if (menu?.length) {
      setMenuList(formatMenu(menu));
    }
  }, [menu]);

  return (
    <>
      <div className={styles.userExpertiseFilertParent}>
        <div className={styles['filter-btn-wrapper']}>
          <Button onClick={handleFilterDropdownShow}>
            Filter By Expertise &nbsp; &nbsp;
            {window.innerWidth < 700 ? (
              ''
            ) : !isFilterDropdownShow ? (
              <FontAwesomeIcon icon={faCaretDown} rotation={270} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} />
            )}
          </Button>
        </div>
        {isFilterDropdownShow && (
          <div className={styles['dashboard-sidebar-wrapper']}>
            <div className={styles['sidebar-list']}>
              <div className={styles.expertisecheckboxContainer}>
                {menuList.map((item) => (
                  <div
                    className={styles.expertisecheckboxWrapper}
                    key={item.id}
                  >
                    <div>
                      {item.icon && (
                        <img
                          src={resolveBEAsset(item.icon.path)}
                          className={styles['list-icon']}
                          alt="Icon"
                        />
                      )}
                      <span>{item.title}</span>
                    </div>

                    <input
                      type="checkbox"
                      value={item.id}
                      checked={selectedMetaDataId.includes(item.id)}
                      onChange={() => handleMenuSelect(item.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExpertiseFilterProfessional;
