import axiosInstance from 'src/config/api';
import { IUserNotificationResponse } from './notification-interface';

export const getUserNotifications = async (
  id: string
): Promise<IUserNotificationResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/user/${id}/notification?populate=userId`
    )
  ).data;
};
