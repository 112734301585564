import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import AttachmentList from 'src/components/attachment-list/attachment-list';
import AvatarRow from 'src/components/avatar-row/avatar-row';
import { UserContext } from 'src/context/user.context';
import {
  deleteCommentReply,
  deleteCommentReplyUpvote,
  postCommentReplyUpvote,
} from 'src/services/post-service/post-service';
import {
  IComment,
  IPost,
  IReply,
} from 'src/services/post-service/post-service.interface';
import { formatDateStr } from 'src/utils/dateUtil';
import styles from './question.module.css';

type IProps = {
  reply: IReply;
  selectedQues: IPost;
  comment: IComment;
  onDelete: (replyId: string) => void;
  onEdit: (replyId: IReply) => void;
};

const ReplyView = ({
  reply: initialReply,
  selectedQues,
  comment,
  onDelete,
  onEdit,
}: IProps) => {
  const [reply, setReply] = useState(initialReply);
  const { id } = useContext(UserContext);
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);
  const [loadingUserUpvotes, setLoadingUserUpvotes] = useState(false);
  const [showReplyMenu, setShowReply] = useState(false);
  const [updating, setUpdating] = useState(false);
  const loggedInUserId = localStorage.getItem('id') || '';

  const addUpvote = async () => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      const upvote = await postCommentReplyUpvote(
        selectedQues._id,
        comment._id,
        reply._id
      );
      setReply((prevReply) => ({
        ...prevReply,
        upvotes: [{ ...upvote, _userId: user }],
        upvoteCount: (prevReply.upvoteCount || 0) + 1,
        interested: true,
      }));
      setLoadingUpvotes(false);
    }
  };

  const removeUpvote = async (userId: string) => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      await deleteCommentReplyUpvote(
        selectedQues._id,
        comment._id,
        reply._id,
        userId
      );
      setReply((prevReply) => ({
        ...prevReply,
        upvotes: [],
        interested: false,
        upvoteCount: prevReply.upvoteCount ? prevReply.upvoteCount - 1 : 0,
      }));
      setLoadingUpvotes(false);
    }
  };

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const self = reply?._userId._id === id;

  const handleDelete = async () => {
    if (selectedQues) {
      setUpdating(true);
      await deleteCommentReply(selectedQues?._id, comment.id, reply._id);
      onDelete(reply._id);
      setUpdating(false);
    }
  };

  return (
    <div className={styles['replies-container']} key={reply._id}>
      <div className={styles['result-avatar']}>
        <AvatarRow showMembership data={reply._userId} />
        <span className={styles['reply-header-date']}>
          {formatDateStr(reply.createdOn)}
        </span>
      </div>
      <div className={styles['reply-content']}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={styles.questionContent} style={{ width: '95%' }}>
            {reply.content}
          </div>
          {selectedQues?.type === 'WORK' && (
            <div
              className={styles['item-interaction-right']}
              style={{ justifyContent: 'flex-end' }}
            >
              {(self ||
                ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(user.role)) && (
                <>
                  <Dropdown
                    className={styles.menuButton}
                    isOpen={showReplyMenu}
                    toggle={() => !updating && setShowReply((old) => !old)}
                    direction="down"
                  >
                    <DropdownToggle>
                      {updating ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin={true}
                          size="sm"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      )}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
                      <DropdownItem onClick={() => onEdit(reply)}>
                        Edit
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              )}
            </div>
          )}
        </div>
        {reply?.attachments?.length > 0 && (
          <AttachmentList attachments={reply.attachments} />
        )}
        {selectedQues?.type !== 'WORK' && (
          <div className={styles['item-interaction-reply']}>
            <div className={styles['item-interaction-left']}>
              {selectedQues?.type !== 'WORK' && (
                <Button
                  className={styles['interaction-btn']}
                  color="transparent"
                  onClick={
                    reply.interested
                      ? () => removeUpvote(loggedInUserId)
                      : addUpvote
                  }
                >
                  <img
                    src={
                      reply.interested
                        ? '/images/upvotedIcon.png'
                        : '/images/upvoteIcon.png'
                    }
                    alt=""
                  />
                  &nbsp;Upvote ({reply?.upvoteCount || 0})
                  {loadingUpvotes ||
                    (loadingUserUpvotes && (
                      <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                    ))}
                </Button>
              )}
            </div>
            <div className={styles['item-interaction-right']}>
              {(self ||
                ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(user.role)) && (
                <>
                  <Dropdown
                    className={styles.menuButton}
                    isOpen={showReplyMenu}
                    toggle={() => !updating && setShowReply((old) => !old)}
                    direction="down"
                  >
                    <DropdownToggle>
                      {updating ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin={true}
                          size="sm"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      )}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
                      <DropdownItem onClick={() => onEdit(reply)}>
                        Edit
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReplyView;
