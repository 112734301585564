import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/navbar/navbar.module.css';
import { getUpdatesSearch } from 'src/services/update-service/update-service';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';

export default function NotificationSearch({
  notiName = '',
  setNotiName,
  isNotiQuery,
  setIsNotiQuery,
}: any) {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const handleNotiNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotiName(event.target.value);
  };

  const handleKeyPressNoti = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Return') {
      urlParams.set('notiName', notiName);
      navigate(`/notification?${urlParams.toString()}`);
      setOptions([]);
      handleCloseDropdown();
    }
  };
  const [options, setOptions] = useState<any[]>([]);
  const getNotificationsByName = async () => {
    if (notiName.length > 0) {
      const res = await getUpdatesSearch(notiName);
      setOptions(res.data);
    }
  };

  const handleOptionClick = (id: any) => {
    inputRef?.current?.blur();
    urlParams.set('notiId', id);
    navigate(`/notification?${urlParams.toString()}`);
    setNotiName('');
    setOptions([]);
    handleCloseDropdown();
  };

  const handleCloseDropdown = () => {
    if (ref.current) {
      setIsNotiQuery(false);
      setOptions([]);
    }
  };
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleCloseDropdown();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsNotiQuery]);

  useEffect(() => {
    getNotificationsByName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notiName]);

  return (
    <div ref={ref}>
      <div
        className={classNames(
          window.innerWidth > 700 && isNotiQuery
            ? styles['wc-search-container-active']
            : window.innerWidth > 700 && !isNotiQuery
            ? styles['wc-search-container']
            : styles['wc-search-container']
        )}
      >
        <div
          className={classNames(
            styles['wc-search'],
            isNotiQuery && styles['wc-search-paddingActive'],
            options.length > 0 && isNotiQuery && styles['wc-search-active']
          )}
          ref={ref}
        >
          {window.innerWidth < 700 ? (
            <div className={styles['search-user-img-mobile']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                width={16}
                height={19}
              />
            </div>
          ) : (
            <div className={styles['search-user-img-windows']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                height={20}
              />
            </div>
          )}
          <input
            type="text"
            ref={inputRef}
            value={notiName}
            onChange={handleNotiNameChange}
            onKeyDown={handleKeyPressNoti}
            onFocus={() => setIsNotiQuery(true)}
            placeholder=" Search for Updates"
            className={styles['wc-search-input']}
            enterKeyHint="go"
          />

          {notiName.length > 0 && (
            <FontAwesomeIcon
              icon={faCircleXmark}
              onClick={() => {
                setNotiName('');
                navigate('/notification');
                handleCloseDropdown();
              }}
            />
          )}
        </div>
        {isNotiQuery && options.length > 0 && (
          <Dropdown.Menu
            show
            className={styles.navbarSeacrhDropdown}
            style={{
              width: ref?.current?.offsetWidth,
              maxWidth: ref?.current?.offsetWidth,
            }}
          >
            {options.map((option) => (
              <Dropdown.Item
                style={{
                  padding: '8px 12px ',
                  borderBottom: '1px solid #ccc',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                key={option.value}
                className={styles['dropdown-option']}
                onClick={() => handleOptionClick(option._id)}
              >
                {option.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </div>
    </div>
  );
}
