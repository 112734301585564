import React, { createRef, useEffect, useRef, useState } from 'react';

import { Form } from 'react-bootstrap';
import styles from './new-comment.module.css';

import { Button } from 'reactstrap';
import { uploadAttachment } from 'src/services/attachment-service/attachment-service.client';
import { postReply } from 'src/services/post-service/post-service';
import {
  IAttachment,
  IComment,
  IPost,
  IReply,
} from 'src/services/post-service/post-service.interface';
import { attchmentList } from '../common';
import { attachmentToFileMap } from '../create-post/create-post.helper';
import { FileList } from '../file-list';
import { LoadingLottie } from '../lotties/loading';

type Props = {
  selectedQues?: IPost;
  selectedComm?: IComment;
  isReply?: boolean;
  initialReplyData?: IReply;
  onCreate?: (reply: IReply) => void;
  onUpdateReply?: (reply: IReply) => void;
};

export default function NewComment({
  selectedComm,
  selectedQues,
  onCreate,
  isReply = false,
  initialReplyData,
  onUpdateReply,
}: Props) {
  const [desc, setDesc] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.focus();
    }

    return () => {
      setDesc('');
      setSelectedFiles([]);
    };
  }, []);

  const setInitialReplyData = async () => {
    if (initialReplyData) {
      setLoading(true);
      setDesc(initialReplyData.content || '');
      const files = await attachmentToFileMap(initialReplyData?.attachments);

      setSelectedFiles(files);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialReplyData) {
      setInitialReplyData();
    }
  }, [initialReplyData]);

  // Ensure that the refs array is always up-to-date with the data array length
  if (inputRefs.current.length !== attchmentList.length) {
    inputRefs.current = Array(attchmentList.length)
      // @ts-ignore
      .fill()
      .map((_, index) => inputRefs.current[index] || createRef());
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files && event.target.files;
    if (files?.length) {
      // Handle the selected image file here
      //@ts-ignore
      setSelectedFiles([...selectedFiles, ...files]);
    }
  };

  const handleButtonClick = (index: number) => {
    // Trigger the file input click event
    if (inputRefs.current[index]) {
      //@ts-ignore
      inputRefs.current[index].current.click();
    }
  };

  function deSelectAttachment(fileIndex: number): void {
    setSelectedFiles([
      ...selectedFiles.filter((file, index) => index !== fileIndex),
    ]);
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    const existingAttachments: Array<IAttachment> = [];
    let newSelectedFile: Array<File> = [];
    if (initialReplyData?.attachments) {
      selectedFiles.forEach((file) => {
        const existingAttachment = initialReplyData?.attachments.find(
          (attachment) => attachment.originalName === file.name
        );
        if (existingAttachment) {
          existingAttachments.push(existingAttachment);
        } else {
          newSelectedFile.push(file);
        }
      });
    } else {
      newSelectedFile = selectedFiles;
    }

    const attachmentsArr = await uploadAttachment(newSelectedFile);
    const reply = await postReply(
      selectedQues?._id as string,
      selectedComm?._id as string,
      desc,
      [...existingAttachments, ...attachmentsArr],
      initialReplyData
    );

    if (initialReplyData) {
      onUpdateReply?.(reply);
    }
    setLoading(false);
    onCreate?.(reply);
  };

  return (
    <div className={styles['new-comment-container']}>
      {loading ? (
        <LoadingLottie height="275px" />
      ) : (
        <>
          <Form.Control
            ref={textRef}
            as="textarea"
            placeholder={isReply ? 'Reply here' : 'Comment here'}
            className={styles['new-comment-text-area']}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          {selectedFiles.length > 0 && (
            <div className={styles.selectedAttachment}>
              <FileList
                files={selectedFiles}
                onClose={(index) => deSelectAttachment(index)}
              />
            </div>
          )}
          <div className={styles['dashboard-search-attachment-list']}>
            {attchmentList.map((item, idx) => (
              <Button
                key={`Attachment_${idx}`}
                className={styles['attachment-btn']}
                color="transparent"
                onClick={() => handleButtonClick(idx)}
                disabled={item.text !== 'Image'}
              >
                <input
                  type="file"
                  accept={`${item.accept}`}
                  style={{ display: 'none' }}
                  ref={inputRefs.current[idx]}
                  onChange={handleImageChange}
                  multiple
                />
                <img src={item.icon} alt={item.text} />
                {item.text}
              </Button>
            ))}
            <div className={styles.postBtnWrapper}>
              <button
                className={styles.postBtn}
                onClick={() => handleSubmit()}
                disabled={!desc.length}
              >
                {initialReplyData ? 'Update' : 'Post'}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
