import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import styles from './events.layout.module.css';
import { Request } from 'src/components/request';
import { resolveBEAsset } from 'src/utils/urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { handleRequestSend } from 'src/utils/messageUtils';
import { IUser } from 'src/services/user-service/user-service.interface';
import EventActions from './event-actions';
import SignupModal from '../stepper/SignupModal';
import classNames from 'classnames';

import {
  deleteEventUpvote,
  eventUpvote,
} from 'src/services/event-service/event-service';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
interface PostUpvote {
  _id: string;
  _eventId: string;
  _userId: string;
  createdOn: string;
  updatedOn: string;
  __v: number;
  id: string;
}

interface Event {
  id: string;
  eventName: string;
  joiningUrl: string;
  eventDesc: string;
  eventImage: string;
  startDateTime: string;
  endDateTime: string;
  upvoteCount: number;
  userId: string;
  cpeCredit: string;
  eventType: string;
  postUpvotes: PostUpvote[];
  interested: boolean;
}

export default function EventListItem({ data }: any) {
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);
  const [upvoteId, setUpvoteId] = useState(data?.postUpvotes[0]?.id || '');
  const [eventData, setEventData] = useState<Event>(data);
  const loggedInUserId = localStorage.getItem('id') || '';
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userDetail, setUserDetail] = useState<IUser | null>(null);
  const getUserDetails = async (userId: string) => {
    const userRes = await fetchUserDetails(userId);
    setUserDetail(userRes);
  };
  useEffect(() => {
    if (loggedInUserId) {
      getUserDetails(loggedInUserId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isCopying, setIsCopying] = useState(false);
  const handleCopyEvent = (data: any) => {
    setIsCopying(true);
    navigator.clipboard.writeText(
      `https://community.whitecollarprofessional.com/events?eventId=${data?.id}`
    );
    setTimeout(() => {
      setIsCopying(false);
    }, 1000);
  };

  const handleEventUpvote = async (id: string) => {
    setLoadingUpvotes(true);
    const res = await eventUpvote(id);

    // @ts-ignore
    const newUpvoteId = res?.id;

    setUpvoteId(newUpvoteId);

    setEventData((prevData) => ({
      ...prevData,
      interested: true,
      upvoteCount: prevData.upvoteCount + 1,
    }));

    setLoadingUpvotes(false);
  };

  const removeUpvote = async () => {
    setLoadingUpvotes(true);

    //@ts-ignore
    await deleteEventUpvote(upvoteId);
    setUpvoteId('');
    setEventData((prevData) => ({
      ...prevData,
      interested: false,
      upvoteCount: prevData.upvoteCount - 1,
    }));

    setLoadingUpvotes(false);
  };

  return (
    <>
      <div className={classNames(styles['event-container'], 'mt-2')}>
        <div className={styles['event-details-container']}>
          <div className={styles['event-image']}>
            {eventData.eventImage ? (
              <img
                src={resolveBEAsset(eventData.eventImage || '')}
                alt="eventImage"
              />
            ) : (
              <img
                src="/images/eventsDefaultImage.png"
                alt="eventDefaultImage"
              />
            )}
          </div>
          <div className={styles['event-details']}>
            <div className={styles['event-details-name']}>
              {eventData.eventName}
            </div>

            <div className={styles['event-details-dialog']}>
              <div className={styles['event-details-key']}>Starts at</div>
              <strong>:</strong>
              <div className={styles['event-details-value']}>
                {new Date(eventData?.startDateTime).toLocaleString('en-IN', {
                  timeZone: 'UTC',
                })}
              </div>
            </div>

            <div className={styles['event-details-dialog']}>
              <div className={styles['event-details-key']}>Ends at</div>
              <strong>:</strong>
              <div className={styles['event-details-value']}>
                {new Date(eventData?.endDateTime).toLocaleString('en-IN', {
                  timeZone: 'UTC',
                })}
              </div>
            </div>
            {eventData.cpeCredit && (
              <div className={styles['event-details-dialog']}>
                {' '}
                <div className={styles['event-details-key']}>
                  {eventData.cpeCredit && 'CPE credit'}
                </div>{' '}
                <strong>:</strong>
                <div className={styles['event-details-value']}>
                  {eventData.cpeCredit}
                </div>
              </div>
            )}

            {!eventData.eventType && (
              <div className={styles['event-details-dialog']}>
                {' '}
                <div className={styles['event-details-key']}>
                  {eventData.joiningUrl.includes('www.') ||
                  eventData.joiningUrl.includes('.co') ||
                  eventData.joiningUrl.includes('.in') ||
                  eventData.joiningUrl.includes('.org')
                    ? 'Web Link'
                    : 'Location'}
                </div>
                <strong>:</strong>
                <div
                  className={styles['event-details-value']}
                  style={{ maxHeight: '50px' }}
                >
                  {eventData.joiningUrl.includes('www.') ||
                  eventData.joiningUrl.includes('.co') ||
                  eventData.joiningUrl.includes('.in') ||
                  eventData.joiningUrl.includes('.org') ? (
                    <a
                      href={eventData.joiningUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        eventData.eventType === 'online' || !eventData.eventType
                          ? styles['events-details-value-url']
                          : ''
                      }
                    >
                      {eventData.joiningUrl}
                    </a>
                  ) : (
                    eventData.joiningUrl
                  )}
                </div>
              </div>
            )}
            {eventData.eventType && (
              <div className={styles['event-details-dialog']}>
                <div className={styles['event-details-key']}>
                  {eventData.eventType === 'online'
                    ? 'Web link'
                    : eventData.eventType === 'offline'
                    ? 'Location'
                    : 'Web link'}
                </div>
                <strong>:</strong>
                <div
                  className={styles['event-details-value']}
                  style={{ maxHeight: '50px' }}
                >
                  {eventData.eventType === 'online' ? (
                    <a
                      href={eventData.joiningUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        eventData.eventType === 'online' || !eventData.eventType
                          ? styles['events-details-value-url']
                          : ''
                      }
                    >
                      {eventData.joiningUrl}
                    </a>
                  ) : (
                    eventData.joiningUrl
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {eventData.eventDesc && (
          <div className={styles['event-details-value-desc']}>
            {eventData.eventDesc}
          </div>
        )}

        <hr style={{ width: '100%', marginBottom: '15px' }} />
        <div className={styles['event-details-interaction-row']}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: '5px',
              marginRight: '5px',
              gap: '25px',
            }}
          >
            <Button
              className={styles['interaction-btn']}
              color="transparent"
              onClick={() => {
                if (!token) {
                  setSignupModal(true);
                } else if (eventData?.interested) {
                  removeUpvote();
                } else {
                  handleEventUpvote(eventData.id);
                }
              }}
            >
              <img
                src={
                  eventData?.interested
                    ? '/images/upvotedIcon.png'
                    : '/images/upvoteIcon.png'
                }
                alt=""
              />
              &nbsp;
              {eventData.interested ? 'Interested' : 'Interested'} (
              {eventData.upvoteCount})
              {loadingUpvotes && (
                <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
              )}
            </Button>
            <Request
              onReset={() => {
                console.log('Reset');
              }}
              onRequest={(args) => {
                if (userDetail) {
                  handleRequestSend(
                    userDetail,
                    args as unknown as IUser[],
                    eventData
                  );
                }
              }}
              label="Invite"
            />
          </div>
          <div style={{ margin: '0 10px 0 auto' }}>
            {!isCopying ? (
              <FontAwesomeIcon
                icon={faCopy}
                onClick={() => handleCopyEvent(data)}
                style={{
                  cursor: 'pointer',
                  fontSize: '18px',
                  color: '#666',
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheck}
                className={styles['check-icon']}
              />
            )}
          </div>
          {isCopying && window.innerWidth <= 700 && (
            <div
              style={{
                position: 'fixed',
                bottom: '110px',
                width: '100%',
                margin: '0 auto',
                paddingRight: '48px',
                zIndex: '999',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  padding: '4px 8px',
                  border: '1px solid #ccc',
                  backgroundColor: '#e6e6e6',
                  width: 'max-content',
                  color: '#1a1a1a',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                }}
              >
                Copied to Clipboard
              </div>
            </div>
          )}
          <div>
            {(userDetail?._id === eventData.userId ||
              userDetail?.role === 'SUPER_ADMIN') && (
              <EventActions postId={eventData.id} type="event" />
            )}
          </div>
        </div>
      </div>

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
}
