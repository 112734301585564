import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import styles from './pin-button.module.css';
import { UserContext } from 'src/context/user.context';
import { deletePinQues, pinQues } from 'src/services/post-service/post-service';
import { IPost, Pin } from 'src/services/post-service/post-service.interface';
import { pinUpdate } from 'src/services/update-service/update-service';

type Props = {
  self: boolean;
  pinId: boolean;
  selectedQues?: IPost;
  type?: Pin;
};

export default function PinButton({ self, pinId, selectedQues, type }: Props) {
  const { id, addFollowing, removeFollowing } = useContext(UserContext);
  const [pinLoading, setPinLoading] = useState(false);
  const postId = selectedQues?._id || '';
  const [isPinned, setIsPinned] = useState(pinId);

  const handleAddPin = async (): Promise<void> => {
    if (selectedQues) {
      setPinLoading(true);
      if (type === 'UPDATE') {
        const { data: newPin } = await pinUpdate(id, selectedQues._id, type);
        addFollowing(newPin);
      } else {
        const { data: newPin } = await pinQues(id, selectedQues._id, type);
        addFollowing(newPin);
      }
      setIsPinned(true);

      setPinLoading(false);
    }
  };

  const handleRemovePin = async (postId: string): Promise<void> => {
    setPinLoading(true);
    if (type === 'UPDATE') {
      const { data: newPin } = await deletePinQues(id, postId, Pin.UPDATE);
      removeFollowing(newPin);
    } else if (type === 'POST') {
      const { data: newPin } = await deletePinQues(id, postId, Pin.POST);
      removeFollowing(newPin);
    }
    setIsPinned(false);
    setPinLoading(false);
  };
  const token = localStorage.getItem('token') || '';
  return (
    <>
      {!self && (
        <>
          <Button
            className={styles['interaction-btn']}
            color="transparent"
            onClick={() =>
              isPinned ? handleRemovePin(postId) : handleAddPin()
            }
            disabled={!token}
          >
            <img
              src={isPinned ? '/images/pinnedIcon.png' : '/images/pinIcon.png'}
              alt=""
            />
            Pin&nbsp;
            {pinLoading && (
              <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
            )}
          </Button>
        </>
      )}
    </>
  );
}
