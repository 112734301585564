import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'src/context/user.context';
import { IUser } from 'src/services/user-service/user-service.interface';
import { handleProfileUpdate } from 'src/services/user-service/user-servicec';
import styles from './profile.module.css';

interface IAchievementSkillsProps {
  user: IUser;
}

const AchievementSkills = ({ user }: IAchievementSkillsProps) => {
  const { id } = useContext(UserContext);
  const [loadingSkillSet, setLoadingSkillSet] = useState(false);
  const [loadingAchievement, setLoadingAchievement] = useState(false);
  const [achievement, setAchievement] = useState<string>(
    user?.achievement || ''
  );
  const [skills, setSkills] = useState<string>(user?.skillSet || '');

  useEffect(() => {
    if (user) {
      setAchievement(user?.achievement || '');
      setSkills(user?.skillSet || '');
    }
  }, [user]);

  const handleBlur = async (key: string, value: string): Promise<void> => {
    if (key === 'achievement') {
      setLoadingAchievement(true);
    } else if (key === 'skillSet') {
      setLoadingSkillSet(true);
    }
    const currUser = await handleProfileUpdate(user.id, key, value);
    if (key === 'achievement') {
      setLoadingAchievement(false);
    } else if (key === 'skillSet') {
      setLoadingSkillSet(false);
    }
  };

  return (
    <div className={styles['as-container']}>
      <Row>
        <Col sm={12} md={6}>
          <h2 className={styles['container-heading']}>
            Achievements&nbsp;
            {loadingAchievement && (
              <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
            )}
          </h2>
          <textarea
            value={achievement}
            onChange={(e) => setAchievement(e.target.value)}
            onBlur={(e) => handleBlur('achievement', e.target.value)}
            className={styles['container-textarea']}
            placeholder={
              id !== user?._id
                ? 'N/A Achievement'
                : 'eg: Chairmen ( consider autoscroll of all the options we have )'
            }
            disabled={id !== user?._id}
          ></textarea>
        </Col>
        <Col sm={12} md={6}>
          <h2 className={styles['container-heading']}>
            Skillset&nbsp;
            {loadingSkillSet && (
              <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
            )}
          </h2>
          <textarea
            value={skills}
            onChange={(e) => setSkills(e.target.value)}
            onBlur={(e) => handleBlur('skillSet', e.target.value)}
            className={styles['container-textarea']}
            placeholder={id !== user?._id ? 'N/A Skillset' : 'eg: GST Expert'}
            disabled={id !== user?._id}
          ></textarea>
        </Col>
      </Row>
    </div>
  );
};

export default AchievementSkills;
