import { IAttachment } from 'src/services/post-service/post-service.interface';
import { resolveBEAsset } from 'src/utils/urls';
import styles from './attachment-list.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignupModal from '../stepper/SignupModal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const getFileIcon = (file: { mimetype: string; originalName: string }) => {
  const extension = (file.originalName.split('.').pop() || '').toLowerCase();

  if (['xls', 'xlsx'].includes(extension)) {
    return '/images/excelFile.png';
  } else if (['pdf'].includes(extension)) {
    return '/images/pdfFile.png';
  } else if (['doc', 'docx'].includes(extension)) {
    return '/images/wordFile.png';
  } else if (['ppt', 'pptx'].includes(extension)) {
    return '/images/pptFile.png';
  } else {
    return '/images/attachmentDefault.png';
  }
};

type Props = {
  size?: 'default' | 'small';
  attachments: IAttachment[];
  data?: any;
};

export default function AttachmentList({
  attachments,
  size = 'default',
  data,
}: Props) {
  const [isPost, setIsPost] = useState(false);
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/post?postId=')) {
      setIsPost(true);
    }
  }, []);

  const router = useNavigate();
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);

  const navigateToPost = () => {
    router(`/post?postId=${data._id}`);
  };
  return (
    <div className={styles['attachmentParent']}>
      {isPost ? (
        <div className={styles.attachmentContainerPost}>
          <Carousel
            showArrows={false}
            autoPlay={false}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            useKeyboardArrows={true}
            swipeable={true}
            dynamicHeight={true}
            className={styles['image-video-carousel']}
          >
            {attachments
              .filter(
                (attachment) =>
                  attachment.mimetype.startsWith('image') ||
                  attachment.mimetype.startsWith('video')
              )
              .map((attachment, index) => {
                const isVideo = attachment.mimetype.startsWith('video');
                const isImage = attachment.mimetype.startsWith('image');
                return (
                  <div key={index} className={styles['video-wrapper']}>
                    {isVideo && (
                      <video
                        className={styles['carousel-video-container']}
                        controls
                        playsInline
                        muted
                        autoPlay
                        onClick={(e) => {
                          const video = e.target as HTMLVideoElement;
                          video.muted = !video.muted;
                        }}
                      >
                        <source
                          src={resolveBEAsset(attachment?.path || '')}
                          type={attachment.mimetype}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {isImage && (
                      <div key={index}>
                        <img
                          src={resolveBEAsset(attachment?.path || '')}
                          alt={attachment?.originalName}
                          className={styles['carousel-image-container']}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </Carousel>
          <div className={styles['attachmentElseImagePost']}>
            {attachments?.map(
              (attachment: IAttachment, index: number) =>
                !attachment.mimetype.startsWith('image') &&
                !attachment.mimetype.startsWith('video') && (
                  <div key={index} style={{ maxHeight: '74px' }}>
                    <>
                      <OverlayTrigger
                        key={attachment._id}
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-${attachment._id}`}>
                            {attachment.originalName}
                          </Tooltip>
                        }
                      >
                        <div
                          id="tooltip"
                          onClick={() => {
                            window.innerWidth < 700
                              ? (window.location.href = resolveBEAsset(
                                  attachment?.path || ''
                                ))
                              : window.open(
                                  resolveBEAsset(attachment?.path || ''),
                                  '_blank'
                                );
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <div>
                              <img
                                className={
                                  size === 'default'
                                    ? styles.attachmentIcon
                                    : styles.attachmentIconSmall
                                }
                                src={getFileIcon(attachment)}
                                alt={attachment.originalName}
                              />
                              <div className={styles['attachment-subtitle']}>
                                {attachment.originalName.length > 7
                                  ? attachment.originalName.slice(0, 7) + '...'
                                  : attachment.originalName}
                              </div>
                            </div>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </>
                  </div>
                )
            )}
          </div>
        </div>
      ) : (
        <div>
          <Carousel
            showArrows={false}
            autoPlay={false}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            useKeyboardArrows={true}
            swipeable={true}
            dynamicHeight={true}
            className={styles['image-video-carousel']}
          >
            {attachments
              .filter(
                (attachment) =>
                  attachment.mimetype.startsWith('image') ||
                  attachment.mimetype.startsWith('video')
              )
              .map((attachment, index) => {
                const isVideo = attachment.mimetype.startsWith('video');
                const isImage = attachment.mimetype.startsWith('image');
                return (
                  <div key={index} className={styles['video-wrapper']}>
                    {isVideo && (
                      <video
                        className={styles['carousel-video-container']}
                        controls
                        playsInline
                        muted
                        autoPlay
                        onClick={(e) => {
                          const video = e.target as HTMLVideoElement;
                          video.muted = !video.muted;
                        }}
                      >
                        <source
                          src={resolveBEAsset(attachment?.path || '')}
                          type={attachment.mimetype}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {isImage && (
                      <div key={index} onClick={() => navigateToPost()}>
                        <img
                          src={resolveBEAsset(attachment?.path || '')}
                          alt={attachment?.originalName}
                          className={styles['carousel-image-container']}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </Carousel>
          <div className={styles['attachmanetElseImageDashboard']}>
            {attachments?.map(
              (attachment: IAttachment, index: number) =>
                !attachment.mimetype.startsWith('image') &&
                !attachment.mimetype.startsWith('video') && (
                  <span key={index}>
                    <div className={styles.attachmentContainer}>
                      <OverlayTrigger
                        key={attachment._id}
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-${attachment._id}`}>
                            {attachment.originalName}
                          </Tooltip>
                        }
                      >
                        <div
                          id="tooltip"
                          onClick={() => {
                            !token
                              ? setSignupModal(true)
                              : data
                              ? router(`/post?postId=${data._id}`)
                              : (window.location.href = resolveBEAsset(
                                  attachment?.path || ''
                                ));
                          }}
                        >
                          {attachment.mimetype.startsWith('image') ? (
                            <div
                              className={
                                index === 0 ? styles.firstImageAttachment : ''
                              }
                            >
                              <img
                                className={
                                  size === 'default'
                                    ? styles.attachmentImage
                                    : styles.attachmentImageSmall
                                }
                                src={resolveBEAsset(attachment?.path || '')}
                                alt={attachment.originalName}
                              />
                              <div className={styles['attachment-subtitle']}>
                                {attachment.originalName.length > 7
                                  ? attachment.originalName.slice(0, 7) + '...'
                                  : attachment.originalName}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <img
                                className={
                                  size === 'default'
                                    ? styles.attachmentIcon
                                    : styles.attachmentIconSmall
                                }
                                src={getFileIcon(attachment)}
                                alt={attachment.originalName}
                              />
                              <div className={styles['attachment-subtitle']}>
                                {attachment.originalName.length > 7
                                  ? attachment.originalName.slice(0, 7) + '...'
                                  : attachment.originalName}
                              </div>
                            </div>
                          )}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </span>
                )
            )}
          </div>
        </div>
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
}
