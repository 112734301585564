import { getMenuData } from 'src/services/meta-data-service/meta-data-service';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { IMetaData } from 'src/services/meta-data-service/meta-data-interface';

interface IMenuContext {
  menu: IMetaData[];
}

const MenuContext = createContext<IMenuContext>({ menu: [] });

const MenuProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [menu, setMenu] = useState<IMetaData[]>([]);

  const fetchMenuData = async () => {
    const res = await getMenuData();
    setMenu(res.data);
  };

  useEffect(() => {
    fetchMenuData();
  }, []);

  return (
    <MenuContext.Provider value={{ menu }}>{children}</MenuContext.Provider>
  );
};

export { MenuProvider, MenuContext };
