import Modal from 'react-bootstrap/Modal';

import { useEffect, useRef, useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import styles from './experience-form.module.css';

import moment from 'moment';

import { Input } from 'reactstrap';
import {
  IExperience,
  IUser,
} from 'src/services/user-service/user-service.interface';
import {
  fetchAllOrganisationNames,
  postExperience,
} from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import { SuccessLottie } from '../lotties/success';
import { ValidationMessage } from '../validation-message';

type ICreatePostProps = {
  user: IUser;
  show: boolean;
  onHide: () => void;
  onCreate: (experience: IExperience) => void;
  onUpdateExperience: (experience: IExperience) => void;
  initialExperienceData: Partial<IExperience>;
};

const getTitle = (type: 'JOB' | 'PRACTICE' | 'OTHER', edit: boolean) => {
  if (edit) {
    switch (type) {
      case 'JOB':
        return 'Update Job';
      case 'PRACTICE':
        return 'Update Practice';
      case 'OTHER':
        return 'Update Occupation';
    }
  }
  switch (type) {
    case 'JOB':
      return 'Add Job';
    case 'PRACTICE':
      return 'Add Practice';
    case 'OTHER':
      return 'Add Occupation';
  }
};

export default function ExperienceForm(props: ICreatePostProps) {
  const { initialExperienceData, onUpdateExperience, onCreate, user } = props;
  const [name, setName] = useState<string>();
  const [designation, setDesignation] = useState<string>();
  const [industry, setIndustry] = useState<string>();
  const [startedOn, setStartedOn] = useState<any>();
  const [endedOn, setEndedOn] = useState<any>();
  const [isPresent, setIsPresent] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const isEdit = !!initialExperienceData?._id;

  const setInitialExperienceData = async () => {
    if (initialExperienceData) {
      setLoading(true);
      const experience = initialExperienceData;
      setName(experience.name);
      setDesignation(experience.designation);
      setIndustry(experience.industry);
      setStartedOn(experience.startedOn);
      setEndedOn(experience.endedOn);
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialExperienceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialExperienceData]);

  const resetForm = (form: HTMLFormElement) => {
    if (form) {
      form.reset();
    }
    setName(undefined);
    setDesignation(undefined);
    setIndustry(undefined);
    setStartedOn(undefined);
    setEndedOn(undefined);
    setLoading(false);
  };

  async function handleExperience(event: any): Promise<void> {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      try {
        if (name && designation) {
          const newExperience = await postExperience(
            user._id,
            name,
            designation,
            industry,
            startedOn,
            endedOn,
            initialExperienceData.type,
            initialExperienceData?._id
          );

          if (isEdit) {
            onUpdateExperience({ ...initialExperienceData, ...newExperience });
          } else {
            onCreate(newExperience);
          }
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            props.onHide();
            resetForm(form);
          }, 2500);
        }
      } catch (e) {
        resetForm(form);
      }
    }
    setValidated(true);
  }

  const pageNumber = 1;
  const pageLimit = 20;

  const getSuggestOrgNames = async () => {
    if (!name) {
      return;
    }
    const res = await fetchAllOrganisationNames(name, pageNumber, pageLimit);
    setNameSuggestions(res.data);
  };

  const handleSelect = (selectedName: any) => {
    setName(selectedName);
    setShowDropdown(false);
    setNameSuggestions([]);
  };

  useEffect(() => {
    if (name) {
      getSuggestOrgNames();
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
      setNameSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  useEffect(() => {
    if (nameSuggestions.length === 0) {
      setShowDropdown(false);
    }
  }, [nameSuggestions]);

  return (
    <Modal
      {...props}
      backdrop="static"
      dialogClassName="modal-90w"
      className={styles['add-work-exp-modal']}
    >
      {!loading && !success && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {getTitle(initialExperienceData?.type || 'JOB', isEdit)}
            </Modal.Title>
          </Modal.Header>
        </>
      )}
      <Modal.Body>
        {success && <SuccessLottie height={410} />}
        {loading && <LoadingLottie height={410} />}
        {!success && !loading && (
          <Form noValidate validated={validated} onSubmit={handleExperience}>
            <Form.Group>
              <Form.Control
                placeholder="Organisation Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                className={styles['exp-form-input']}
                ref={inputRef}
              />
              {showDropdown && (
                <Dropdown.Menu
                  show
                  style={{
                    fontSize: '12px',
                    maxHeight: '100px',
                    marginTop: '2px',
                    overflowY: 'auto',
                    width: inputRef.current
                      ? inputRef.current.offsetWidth
                      : 'auto',
                  }}
                >
                  {nameSuggestions.map((suggestion, index) => (
                    <Dropdown.Item
                      style={{
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        borderBottom: '1px dotted #ccc',
                      }}
                      key={index}
                      onClick={() => handleSelect(suggestion)}
                    >
                      {suggestion}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              )}
              <Form.Control.Feedback type="invalid">
                <ValidationMessage>This field is required.</ValidationMessage>
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Control
                placeholder="Designation"
                value={designation}
                required
                onChange={(e) => setDesignation(e.target.value)}
                className={styles['exp-form-input']}
              />
              <Form.Control.Feedback type="invalid">
                <ValidationMessage>This field is required.</ValidationMessage>
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Currently Working Here"
                onChange={(e) => setIsPresent(e.target.checked)}
                className={styles['exp-form-input']}
                style={{
                  border: 'none',
                  marginLeft: '4px',
                  marginBottom: '8px',
                  borderColor: '#1a1a1a',
                }}
              />
            </Form.Group>
            {/* <br /> */}
            {/* {
              initialExperienceData?.type === "OTHER" &&
              <>
                <Form.Group>
                  <Form.Control
                    placeholder='Industry'
                    value={industry}
                    required
                    onChange={(e) => setIndustry(e.target.value)}
                    style={{ border: '1px solid #E6E6E6', borderRadius: '12px' }}
                  />
                  <Form.Control.Feedback type="invalid">
                    <ValidationMessage>This field is required.</ValidationMessage>
                  </Form.Control.Feedback>
                </Form.Group>
                <br />
              </>
            } */}
            <div className={styles['add-job-date-wrapper']}>
              <Form.Group style={{ width: '100%' }}>
                <Form.Label className={styles['add-job-date-label']}>
                  Started on
                </Form.Label>
                <Input
                  type="date"
                  value={
                    startedOn
                      ? moment(startedOn).format('YYYY-MM-DD')
                      : undefined
                  }
                  onChange={(e) => setStartedOn(e.target.value)}
                  className={styles['add-job-date-input']}
                />
                <Form.Control.Feedback type="invalid">
                  <ValidationMessage>This field is required.</ValidationMessage>
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group style={{ width: '100%' }}>
                {/* {className={styles['ended-on-label']}} */}
                <Form.Label className={styles['add-job-date-label']}>
                  Ended on
                </Form.Label>
                <Input
                  type="date"
                  value={
                    endedOn ? moment(endedOn).format('YYYY-MM-DD') : undefined
                  }
                  onChange={(e) => setEndedOn(e.target.value)}
                  className={styles['add-job-date-input']}
                  disabled={isPresent}
                />
                <Form.Control.Feedback type="invalid">
                  <ValidationMessage>This field is required.</ValidationMessage>
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <br />
            <div className={styles.postBtnWrapper}>
              <button type="submit" className={styles.postBtn}>
                {isEdit ? 'Update' : 'Add'}
              </button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}
