import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap';

import {
  faCheck,
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import AttachmentList from 'src/components/attachment-list/attachment-list';
import AvatarRow from 'src/components/avatar-row/avatar-row';
import CreatePost from 'src/components/create-post';
import { LoadingLottie } from 'src/components/lotties/loading';
import { Request } from 'src/components/request';
import { UserContext } from 'src/context/user.context';
import {
  deletePost,
  deletePostUpvote,
  fetchAllPostUpvotes,
  fetchPostPin,
  getCommentAnswers,
  getPost,
  getPostUpvotes,
  postPostUpvote,
} from 'src/services/post-service/post-service';
import {
  IComment,
  IPost,
  Pin,
} from 'src/services/post-service/post-service.interface';
import { IUser } from 'src/services/user-service/user-service.interface';
import { formatDateStr } from 'src/utils/dateUtil';
import CommentView from './comment';
import styles from './question.module.css';
import { handleRequestSend } from 'src/utils/messageUtils';
import PinButton from 'src/components/pin-button';
import classNames from 'classnames';

const Result = ({ profilePage = false }: { profilePage?: boolean }) => {
  const { id } = useContext(UserContext);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const postId = query.get('postId');
  const loggedInUserId = localStorage.getItem('id') || '';

  const [show, setShow] = useState<boolean>(false);
  const [selectedQues, setSelectedQues] = useState<IPost>();
  const [commentAnswers, setCommentAnswers] = useState<IComment[]>();
  const [loadingPost, setLoadingPost] = useState(true);
  const [showQuestionMenu, setShowQuestionMenu] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [editQuestion, setEditQuestion] = useState<IPost>();
  const [editComment, setEditComment] = useState<IComment>();
  const router = useNavigate();
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);
  const postPageUrl = `https://community.whitecollarprofessional.com/post`;
  const [isCopying, setIsCopying] = useState(false);

  const handleCopyPost = (data: any) => {
    setIsCopying(true);
    navigator.clipboard.writeText(`${postPageUrl}?postId=${data?.id}`);
    setTimeout(() => {
      setIsCopying(false);
    }, 1000);
  };
  const fetchUserUpvotes = async () => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      const userUpvotes = (
        await getPostUpvotes(selectedQues._id, { pageNumber: 1, pageSize: 1 })
      ).data;
      setSelectedQues((prevData) =>
        prevData ? { ...prevData, upvotes: userUpvotes } : prevData
      );
      setLoadingUpvotes(false);
    }
  };

  useEffect(() => {
    if (selectedQues?.type === 'WORK' && !selectedQues?.upvotes) {
      fetchUserUpvotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQues]);

  const addUpvote = async () => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      const upvote = await postPostUpvote(selectedQues._id);
      setSelectedQues((prevData) =>
        prevData
          ? {
              ...prevData,
              upvotes: [{ ...upvote, _userId: user }],
              upvoteCount: (prevData.upvoteCount || 0) + 1,
            }
          : prevData
      );
      setLoadingUpvotes(false);
    }
  };

  const removeUpvote = async (userId: string) => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      await deletePostUpvote(selectedQues._id, userId);
      setSelectedQues((prevData) =>
        prevData
          ? {
              ...prevData,
              upvotes: [],
              upvoteCount: prevData.upvoteCount ? prevData.upvoteCount - 1 : 0,
            }
          : prevData
      );
      setLoadingUpvotes(false);
    }
  };

  const self = selectedQues?._userId?._id === id;

  const fetchQues = async (id: string) => {
    setLoadingPost(true);
    setSelectedQues(await getPost(id));

    setLoadingPost(false);
  };
  const [currentPin, setCurrentPin] = useState(false);
  const getPostPins = async () => {
    const res = await fetchPostPin(postId);

    if (res.data.length > 0) {
      setCurrentPin(true);
    } else {
      setCurrentPin(false);
    }
  };

  useEffect(() => {
    getPostPins();
  }, []);

  const fetchAnswerComment = async (id: string) => {
    setLoadingPost(true);
    const comment = await getCommentAnswers(id);
    const newCommentList = [...comment.data];
    const postIds = newCommentList.map((comment) => comment.id).filter(Boolean);
    const response = await fetchAllPostUpvotes(postIds, 'comment');
    const upvotesMap = response.data.data;
    const updatedCommentList = newCommentList.map((comment) => ({
      ...comment,
      postUpvotes: upvotesMap[comment._id] || [],
    }));
    const commentListInterseted = updatedCommentList.map((comment) => ({
      ...comment,
      interested: comment?.postUpvotes.some(
        (item: any) => item._userId === loggedInUserId
      ),
    }));
    setCommentAnswers(commentListInterseted);
    setLoadingPost(false);
  };

  useEffect(() => {
    if (postId) {
      fetchQues(postId as string);
      fetchAnswerComment(postId as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  const user = JSON.parse(localStorage.getItem('user') || '{}') as IUser;

  const isWork = selectedQues?.type === 'WORK';

  const handleDelete = async () => {
    if (selectedQues) {
      setUpdating(true);
      await deletePost(selectedQues?._id);
      setUpdating(false);
      router('/dashboard');
    }
  };
  const handleEdit = () => {
    setEditQuestion(selectedQues);
    setShow(true);
  };

  const haveUpvoted = selectedQues?.upvotes?.find(
    ({ _userId }) => _userId?._id === id
  );

  return (
    <div className={styles['questionResultParent']}>
      {window.innerWidth < 700 && (
        <div className={styles['question-navbar']}>
          <img src="/images/back-arrow.svg" alt="" />
          <Button
            onClick={() => {
              if (router(-1) !== undefined) {
                router(-1);
              } else {
                router('/dashboard');
              }
            }}
            color="transparent"
          >
            back
          </Button>
        </div>
      )}
      {loadingPost ? (
        <LoadingLottie height="400px" />
      ) : (
        <div className={styles['wc-result']}>
          <div className={styles['wc-result-question']}>
            <div className={styles['question-header-wrapper']}>
              <AvatarRow showMembership data={selectedQues?._userId as IUser} />
              <span className={styles['question-header-date']}>
                {' '}
                <li>{formatDateStr(selectedQues?.createdOn as string)}</li>{' '}
              </span>
            </div>

            <div
              className={
                (classNames(styles['qa-container']), styles['quesConationer'])
              }
            >
              <h3>{selectedQues?.title}</h3>

              <p
                className={styles.questionContent}
                dangerouslySetInnerHTML={{
                  __html: selectedQues?.content || '',
                }}
              />

              {selectedQues?.attachments &&
                selectedQues?.attachments?.length > 0 && (
                  <AttachmentList attachments={selectedQues?.attachments} />
                )}
              {!(selectedQues?.type === 'QUESTION' && !user.isProfessional) && (
                <Input
                  type="text"
                  className={styles['add-answer-input']}
                  placeholder={
                    selectedQues?.type === 'WORK'
                      ? 'Comment here'
                      : 'Type your answer'
                  }
                  onClick={() => setShow(true)}
                />
              )}
              <div className={styles['item-interaction']}>
                <div className={styles['item-interaction-left']}>
                  {isWork ? (
                    <Button
                      className={`${styles['interaction-btn']} ${styles['answer-btn']}`}
                      color="transparent"
                      onClick={() => setShow(true)}
                    >
                      <img src="/images/commentIcon.png" alt="" />
                      &nbsp;Comment ({selectedQues?.commentCount})
                    </Button>
                  ) : (
                    <Button
                      className={`${styles['interaction-btn']} ${styles['answer-btn']}`}
                      color="transparent"
                      onClick={() =>
                        user.isProfessional ? setShow(true) : null
                      }
                      disabled={!user.isProfessional}
                    >
                      <img src="/images/answerIcon.png" alt="" />
                      &nbsp;Answer ({selectedQues?.commentCount})
                    </Button>
                  )}
                  {user.isProfessional && (
                    <Request
                      onReset={() => {
                        console.log('Reset');
                      }}
                      onRequest={(args) => {
                        handleRequestSend(
                          user,
                          args as unknown as IUser[],
                          selectedQues as IPost,
                          'POST'
                        );
                      }}
                      label={
                        selectedQues?.type === 'QUESTION' ? 'Request' : 'Share'
                      }
                    />
                  )}

                  <PinButton
                    self={self}
                    // @ts-ignore
                    pinId={currentPin}
                    selectedQues={selectedQues}
                    type={Pin.POST}
                  />
                  {selectedQues?.type === 'WORK' && (
                    <Button
                      className={styles['interaction-btn']}
                      color="transparent"
                      onClick={
                        selectedQues?.upvotes?.find(
                          ({ _userId }) => _userId?._id === id
                        )
                          ? () => removeUpvote(id)
                          : addUpvote
                      }
                    >
                      <img
                        src={
                          haveUpvoted
                            ? '/images/upvotedIcon.png'
                            : '/images/upvoteIcon.png'
                        }
                        alt=""
                      />
                      &nbsp;Upvote ({selectedQues?.upvoteCount || 0})
                      {loadingUpvotes && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin={true}
                          size="sm"
                        />
                      )}
                    </Button>
                  )}
                </div>

                <div className={styles['item-interaction-right']}>
                  {!isCopying ? (
                    <FontAwesomeIcon
                      icon={faCopy}
                      onClick={() => handleCopyPost(selectedQues)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '18px',
                        color: '#666',
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={styles['check-icon']}
                    />
                  )}

                  {isCopying && window.innerWidth <= 700 && (
                    <div
                      style={{
                        position: 'fixed',
                        bottom: '110px',
                        width: '100%',
                        margin: '0 auto',
                        paddingRight: '48px',
                        zIndex: '999',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 500,
                          padding: '4px 8px',
                          border: '1px solid #ccc',
                          backgroundColor: '#e6e6e6',
                          width: 'max-content',
                          color: '#1a1a1a',
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}
                      >
                        Copied to Clipboard
                      </div>
                    </div>
                  )}
                  {(self ||
                    ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                      user.role
                    )) && (
                    <Dropdown
                      className={styles.menuButton}
                      isOpen={showQuestionMenu}
                      toggle={() => setShowQuestionMenu((old) => !old)}
                      direction="down"
                    >
                      <DropdownToggle>
                        {updating ? (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin={true}
                            size="sm"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={handleEdit}>Edit</DropdownItem>
                        <DropdownItem onClick={handleDelete}>
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* QUESTION */}

          {/* EACH ANSWER */}

          {commentAnswers?.map((comment) => (
            <div key={comment._id} className={styles['wc-result-question']}>
              <CommentView
                onDelete={(commentId) => {
                  setCommentAnswers((prevRepComment) => {
                    return [
                      ...(prevRepComment || []).filter(
                        ({ _id }) => _id !== commentId
                      ),
                    ];
                  });
                  setSelectedQues((prevQues) =>
                    prevQues
                      ? {
                          ...prevQues,
                          commentCount: prevQues.commentCount
                            ? prevQues.commentCount - 0
                            : 0,
                        }
                      : undefined
                  );
                }}
                onEdit={(comment) => {
                  setEditComment(comment);
                  setShow(true);
                }}
                comment={comment}
                selectedQues={selectedQues!}
              />
            </div>
          ))}
        </div>
      )}
      {selectedQues && (
        <CreatePost
          initialPostData={editQuestion}
          initialCommentData={editComment}
          onCreateComment={(comment) => {
            setCommentAnswers((prevComments) => {
              return [{ ...comment, _userId: user }, ...(prevComments || [])];
            });
            setSelectedQues((prvQuestion) =>
              prvQuestion
                ? {
                    ...prvQuestion,
                    commentCount: (prvQuestion.commentCount || 0) + 1,
                  }
                : prvQuestion
            );
          }}
          onUpdatePost={() => {
            fetchQues(selectedQues?.id);
          }}
          onUpdateComment={() => {
            fetchAnswerComment(selectedQues?.id);
          }}
          show={show}
          onHide={() => {
            setEditQuestion(undefined);
            setShow(false);
          }}
          isAnswer={!editQuestion && !isWork}
          isComment={!editQuestion && isWork}
          ques={selectedQues}
        />
      )}
    </div>
  );
};

export default Result;
