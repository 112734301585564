import { IMetaData } from '../meta-data-service/meta-data-interface';
import { IUser } from '../user-service/user-service.interface';

export interface IAttachment {
  name: string;
  originalName: string;
  extension: string;
  mimetype: string;
  path: string;
  destination: string;
  size: number;
  _id: string;
  id: string;
}

export interface IPost {
  _id: string;
  _userId: IUser;
  title: string;
  content: string;
  type: string;
  attachments: IAttachment[];
  commentCount: number;
  upvoteCount: number;
  createdOn: string;
  updatedOn: string;
  plainContent: string;
  plainText: string;
  upvotes?: IReply[];
  postUpvotes: PostUpvote[];
  postPins?: PostPins[];
  expertise?: IMetaData[];
  professionalOnly?: boolean;
  __v: number;
  id: string;
  notificationPriority?: string;
  notificationDescription?: string;
  notificationSource?: string;
}

export interface IEvent extends IPost {
  [x: string]: any;
  link: string;
  startedOn: string;
  endedOn: string;
  cpeCredit: string;
  notificationPriority: string;
  notificationSource: string;
}

export interface IEventResponse {
  total: number;
  pageSize: number;
  pageNumber: number;
  data: IEvent[];
}

export interface IPostResponse {
  data: IPost[];
  pageNumber: number;
  pageSize: number;
  total: number;
}

export interface IReply {
  _id: string;
  _userId: IUser;
  _commentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  attachments: IAttachment[]; // Update with Attachment interface if needed
  plainContent: string;
  upvoteCount: number;
  upvotes?: IReply[];
  postUpvotes?: PostUpvote[];
  postPins?: PostPins[];
  interested?: boolean;
  __v: number;
  id: string;
}
export interface PostUpvote {
  _id: string;
  _userId: string;
  _commentId: string;
  createdOn: string;
  updatedOn: string;
  __v: number;
  id: string;
  _eventId: string;
}
export interface PostPins {
  _id: string;
  _userId?: string;
  postId: string;
  createdOn?: string;
  updatedOn?: string;
  __v?: number;
  id: string;
  type?: string;
}

export interface IComment {
  _id: string;
  _userId: IUser;
  _postId: string;
  _commentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  attachments: IAttachment[];
  plainContent: string;
  replyCount: number;
  upvoteCount: number;
  replies?: IReply[];
  upvotes?: IReply[];
  __v: number;
  id: string;
  postUpvotes: PostUpvote[];
  interested: boolean;
}

export interface ICommentResponse {
  data: IComment[];
  pageNumber: number;
  pageSize: number;
  total: number;
}
export interface IJob extends IPost {
  endedOn: string;
  link: string;
  jobQualification: string;
  jobLocation: string;
  jobOpening: string;
  jobExperience: string;
  jobAmount: string;
  jobSkills: string;
  jobType: string;
  jobTenure: string;
  jobContactName: string;
  jobContactNumber: string;
  jobContactEmail: string;
  jobId: string;
}

export interface IJobResponse {
  total: number;
  pageSize: number;
  pageNumber: number;
  data: IJob[];
}
export enum Pin {
  USER = 'USER',
  POST = 'POST',
  NOTIFICATION = 'NOTIFICATION',
  UPDATE = 'UPDATE',
}
