import React, { useEffect, useState } from 'react';
import {
  ITrend,
  ITrendComment,
} from 'src/services/trend-service/trend-service.interface';
import SignupModal from '../stepper/SignupModal';
import {
  getTrendComment,
  postTrendComment,
} from 'src/services/trend-service/trend-service';
import { Button, Input } from 'reactstrap';
import styles from './trend.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import TrendCommentListItem from './TrendCommentListItem';

interface TrendViewProps {
  data: ITrend;
}

const TrendCommentView: React.FC<TrendViewProps> = ({ data }) => {
  const [signupModal, setSignupModal] = useState(false);
  const [trendComment, setTrendComment] = useState<ITrendComment[]>();
  const [commentShow, setCommentShow] = useState(false);
  const token = localStorage.getItem('token');
  const [currentTrend, setCurrentTrend] = useState('');
  const [comment, setComment] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(4);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [noMoreComments, setNoMoreComments] = useState(false); //
  const handleComment = (trendId: string) => {
    setCommentShow(true);
    setCurrentTrend(trendId);
  };

  const postComment = async () => {
    await postTrendComment(currentTrend, comment);
    setCommentShow(false);
    setComment('');
    setPageNumber(1);
    setTrendComment([]);
    fetchComments();
  };

  const fetchComments = async () => {
    const res = await getTrendComment(data?._id, pageNumber, pageSize);

    if (pageNumber === 1) {
      setTrendComment(res.data);
    } else {
      setTrendComment((prevComments) => [...(prevComments || []), ...res.data]);
    }
    setHasMoreComments(res.data.length === pageSize);
    setNoMoreComments(res.data.length < pageSize); // Set no more comments if we receive less than page size
  };

  useEffect(() => {
    if (data?._id) {
      fetchComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id, pageNumber]);

  const loadMoreComments = () => {
    setPageNumber((prevPage) => prevPage + 1);
  };

  return (
    <>
      {trendComment?.map((item) => (
        <TrendCommentListItem key={item.id} item={item} />
      ))}

      {!noMoreComments && hasMoreComments && trendComment && (
        <div className="text-center">
          <Button
            color="transparent"
            onClick={loadMoreComments}
            style={{ border: 'none', fontSize: '12px', padding: '4px 6px' }}
            className="mt-2"
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ color: '#1a1a1a' }}
            />
          </Button>
        </div>
      )}
      {!commentShow ? (
        <input
          onClick={() => {
            token ? handleComment(data._id) : setSignupModal(true);
          }}
          value={comment}
          type="text"
          placeholder="Your comment here..."
          className={styles.commentInput}
        />
      ) : (
        <div className={styles['trend-view-comment-box']}>
          <Input
            type="textarea"
            value={comment}
            placeholder="Enter your comment here..."
            onChange={(e) => setComment(e.target.value)}
            className={styles['trend-view-comment-textarea']}
            maxLength={256}
          />
          <div className={styles['char-count']}>{comment.length}/256</div>
          <div className={styles.commentSubmit}>
            <button onClick={postComment} className={styles['postBtn']}>
              Post Comment
            </button>
          </div>
        </div>
      )}

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default TrendCommentView;
