import { useContext, useState } from 'react';
import { MessageContext } from 'src/context/message.context';
import { resolveBEAsset } from 'src/utils/urls';
import styles from './chat-list-mobile.module.css';
import BottomBar from '../bottombar/BottomBar';
import { useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';
const ChatList = ({ refreshList }: any) => {
  const { openChatWindow } = useContext(MessageContext);

  const [searchTerm, setSearchTerm] = useState('');
  const { _id } = JSON.parse(localStorage.getItem('user') || '{}');

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filteredChats = refreshList?.filter((chat: any) =>
    chat?.user?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatMessage = (content: any) => {
    if (typeof content === 'object') {
      if (content?.type === 'text') {
        return content?.content;
      } else if (content?.type === 'link') {
        return content?.content;
      }
    } else {
      return content;
    }
    return content;
  };
  const navigate = useNavigate();

  const hadleOpenMobileChat = (chat: any) => {
    navigate({
      pathname: '/chatwindow',
      search: `userid=${chat?.user?._id}`,
    });
    openChatWindow(chat);
  };

  return (
    <div className={styles['chat-list']}>
      <Input
        color="transparent"
        type="text"
        placeholder="Search by Name"
        value={searchTerm}
        onChange={handleSearchChange}
        className={styles['search-input']}
      />

      <ul style={{ marginBottom: '100px' }}>
        {filteredChats.map((chat: any) => (
          <li key={chat?.user?._id} onClick={() => hadleOpenMobileChat(chat)}>
            <span style={{ cursor: 'pointer' }}>
              <div className={styles['user-img-wrapper']}>
                <img
                  src={
                    chat?.user?.picture?.path
                      ? resolveBEAsset(chat?.user?.picture?.path || '')
                      : '/images/defaultProfilePic.svg'
                  }
                  alt={`Profile of ${chat?.user?.name}`}
                />
              </div>
              <div className={styles['chat-details']}>
                <h3>
                  {chat?.user?.name} {''}
                  {chat?.user?.isProfessional && (
                    <img
                      src="/images/prof-verification.png"
                      alt="VerifiedLogo"
                      style={{
                        height: '15px',
                        width: '15px',
                      }}
                    />
                  )}
                </h3>
                <p>
                  <span>{chat?.message?._userId === _id ? 'You: ' : ''}</span>
                  {formatMessage(chat?.message?.content)}
                </p>
              </div>
            </span>
          </li>
        ))}
      </ul>
      <BottomBar />
    </div>
  );
};

export default ChatList;
