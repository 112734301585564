import axiosInstance from 'src/config/api';

export const uploadAttachment = async (selectedFiles: (File | null)[]) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  const promises = selectedFiles.map((selectedFile) => {
    const formDataObject = new FormData();
    formDataObject.append('file', selectedFile as Blob);

    return axiosInstance.post(`${baseUrl}/api/v1/file`, formDataObject, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });

  return (await Promise.all(promises)).map((res) => res.data);
};
