import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import { fetchUser, updateUser } from 'src/services/user-service/user-servicec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faEnvelope,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Pagination from '@mui/material/Pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './adminPanel.module.css';
import MembershipModal from './membershipModal';
import { resolveBEAsset } from 'src/utils/urls';

const excludedKeys = [
  'isHideContactNumber',
  'isHideEmailAddress',
  'last',
  'attempts',
  '_id',
  'picture',
  '__v',
  'memberships',
  'id',
  'updatedOn',
  'skillSet',
  'achievement',
];

const MainBody = () => {
  const userTableHeading = [
    'Name',
    'Email Address',
    'Contact Number',
    'City',
    'State',
    'Aadhaar Number',
    'Aadhaar Picture',
    'Membership Name',
    'Membership Number',
    'Membership Attachment',
    'Role',
    'Status',
    'Created At',
    'Updated At',
    'Action',
  ];
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesSize, setPageSize] = useState(100);
  const dropDownList = ['INACTIVE', 'ACTIVE'];
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('INACTIVE');
  const [action, setAction] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState({});

  const handleClose = () => setShowModal(false);

  const style = {
    margin: '10px',
  };

  const styleMargin = {
    marginLeft: '90%',
  };

  const convertToCSV = (array) => {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((item) => Object.values(item).join(',')).join('\n');
    return header + rows;
  };

  const handleMembershipToggle = (tableData, membership) => {
    setSelectedMembership(membership);
    setShowMembershipModal(true);
    setSelectedUser(tableData);
  };

  const handleChangeList = (status) => {
    setStatus(status);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getFilteredData = (data) => {
    const filteredData = data.map((item) => {
      const { memberships, ...rest } = item;
      const extractMemberShipName = memberships.map(
        (membership) => membership.name
      );
      const extractMemberShipId = memberships.map(
        (membership) => membership.id
      );
      const filteredItem = {
        ...rest,
        membershipName: extractMemberShipName.join(' '),
        membershipId: extractMemberShipId.join(' '),
      };
      excludedKeys.forEach((key) => delete filteredItem[key]);
      return filteredItem;
    });
    return filteredData;
  };

  const handleDownlaod = () => {
    setIsLoading(true);
    const currentDate = getCurrentDate();
    const fileName = `user_data_${currentDate}.csv`;
    const csvData = convertToCSV(getFilteredData(data));
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
    setIsLoading(false);
  };

  const handleStatusClick = (rowData) => {
    setSelectedUser(rowData);
    setShowModal(true);
  };

  const handleConfirmStatus = async (data) => {
    setIsLoading(true);
    setAction(data.id + data.status);
    try {
      let UserId = data.id;
      const payload = {
        status: status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      };
      await updateUser(UserId, payload);
      fetchData();
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      console.log('error while updating the user', error);
      setShowModal(false);
    }
  };

  const fetchData = async () => {
    try {
      let params = {
        status: status,
        pageNumber: currentPage,
        pageSize: pagesSize,
        sortBy: 'createdOn',
      };
      const fetchData = await fetchUser(params);
      setData(fetchData?.data);
      setIsLoading(false);
      setTotalPages(fetchData?.data?.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, action, currentPage, pagesSize]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event, newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
    // onDateChange(date);
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
    // onDateChange(date);
  };

  return (
    <>
      {showMembershipModal ? (
        <MembershipModal
          showModal={showMembershipModal}
          setShowMembershipModal={setShowMembershipModal}
          setSelectedMembership={setSelectedMembership}
          membership={selectedMembership}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          fetchData={fetchData}
        />
      ) : (
        <></>
      )}
      <div className="text-center mt-5">
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : null}
      </div>
      <div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Review Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              <>
                Do you{' '}
                <strong>{status === 'INACTIVE' ? 'Confirm' : 'Cancel'}</strong>{' '}
                review for <strong>{selectedUser?.name}</strong>
              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleConfirmStatus(selectedUser)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div style={{}}>
        <div className="header-container">
          <div className="date-inputs">
            <label htmlFor="">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={onStartDateChange}
              dateFormat="yyyy-MM-dd"
              placeholderText="Start Date"
              className="date-picker"
            />
            <label htmlFor="">End Date</label>
            <DatePicker
              selected={endDate}
              onChange={onEndDateChange}
              dateFormat="yyyy-MM-dd"
              placeholderText="End Date"
              className="date-picker"
            />
          </div>
          <div style={{ marginLeft: '89%', marginTop: '-5%' }}>
            <Dropdown data-bs-theme="dark">
              <Dropdown.Toggle
                id="dropdown-button-status-change"
                variant="secondary"
              >
                Review Status
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {dropDownList.map((data, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleChangeList(data)}
                    >
                      {data}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div style={styleMargin}>
        <FontAwesomeIcon
          onClick={() => handleDownlaod()}
          style={style}
          icon={faDownload}
        />
        <FontAwesomeIcon style={style} icon={faEnvelope} />
        <FontAwesomeIcon style={style} icon={faPrint} />
      </div>
      {/* <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    pageSize={pagesSize}
                    pagination
                />
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onRowsPerPageChange={handlePageSizeChange}
                    onChange={handlePageChange}
                    rowCount={totalPages * pagesSize}
                />
            </div> */}
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            {userTableHeading.map((thData, index) => {
              return <th key={index}> {thData}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((tableData, index) => {
            return (
              <tr key={tableData.id} style={{ verticalAlign: 'middle' }}>
                <td>{index + 1}</td>
                <td>{tableData.name}</td>
                <td>{tableData.emailAddress}</td>
                <td>{tableData.contactNumber}</td>
                <td>{tableData.city}</td>
                <td>{tableData.state}</td>
                <td>{tableData.aadhaarNumber}</td>
                <td>
                  <img
                    src={
                      tableData?.picture?.path
                        ? resolveBEAsset(tableData?.picture?.path || '')
                        : '/images/defaultProfilePic.svg'
                    }
                    alt="profile-pic"
                    width="100"
                    height="100"
                  />
                </td>
                <td>
                  <ul className={styles['membership-list']}>
                    {tableData.memberships
                      ?.filter((item) => item.name !== 'NONE')
                      .map((membership, index) => {
                        return (
                          <li key={membership.id}>
                            <label>
                              <input
                                type="checkbox"
                                checked={membership.isVerified}
                                onClick={() =>
                                  !membership.isVerified
                                    ? handleMembershipToggle(
                                        tableData,
                                        membership
                                      )
                                    : false
                                }
                              />
                              {membership.name}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </td>
                <td>
                  <ul>
                    {tableData.memberships
                      ?.filter((item) => item.number !== 'None Selected')
                      .map((membership, index) => {
                        return <li>{membership.number}</li>;
                      })}
                  </ul>
                </td>
                <td>
                  {tableData?.memberships &&
                    tableData?.memberships.map((MembershipObj) => (
                      <>
                        {MembershipObj?.attachments &&
                          MembershipObj?.attachments?.map((attachment) => (
                            <a
                              target="_blank"
                              href={`${resolveBEAsset(attachment?.path || '')}`}
                              rel="noopener noreferrer"
                            >
                              {MembershipObj.name}
                            </a>
                          ))}
                      </>
                    ))}
                </td>
                <td>{tableData.role}</td>
                <td>{tableData.status}</td>
                <td>{new Date(tableData.createdOn).toLocaleDateString()}</td>
                <th>
                  {new Date(tableData.createdOn).toLocaleDateString() +
                    ' ' +
                    new Date(tableData.createdOn).toLocaleTimeString()}
                </th>
                <td>
                  <Button
                    variant={
                      tableData.status === 'ACTIVE' ? 'success' : 'secondary'
                    }
                    type="submit"
                    onClick={() => handleStatusClick(tableData)}
                  >
                    {tableData.status}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        count={totalPages}
        page={currentPage}
        onRowsPerPageChange={handlePageSizeChange}
        onChange={handlePageChange}
        rowCount={totalPages * pagesSize}
      />
    </>
  );
};

export default MainBody;
