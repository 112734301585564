import React, { useEffect, useState } from 'react';
import Layout from 'src/layouts/layout';
import styles from './trend.module.css';
import { Button } from 'reactstrap';
import SignupModal from 'src/components/stepper/SignupModal';

import {
  fetchAllTrendUpvotes,
  fetchTrends,
} from 'src/services/trend-service/trend-service';
import { ITrend } from 'src/services/trend-service/trend-service.interface';
import 'react-quill/dist/quill.snow.css';
import TrendView from 'src/components/trend/TrendView';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { CreateTrend } from './CreateTrend';
import BottomBar from 'src/components/bottombar/BottomBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';

const Trend = () => {
  const [searchParams] = useSearchParams();
  const trendId = searchParams.get('trendId') || '';
  const [signupModal, setSignupModal] = useState(false);
  const [sortBy, setSortBy] = useState('updatedOn');
  const id = localStorage.getItem('id');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [allTrends, setAllTrends] = useState<ITrend[]>();
  const [refresh, setRefresh] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noMoreItems, setNoMoreItems] = useState(false);
  const [isTrendId, setIsTrendId] = useState(false);
  const trendName = searchParams.get('trendName') || '';
  const navigate = useNavigate();
  const [dummyTrigger, setDummyTrigger] = useState<number>(1);

  const getTrends = async () => {
    setIsLoading(true);
    try {
      const res = await fetchTrends(pageNumber, sortBy, trendId, trendName);
      const response = res.data;
      if (pageNumber === 1) {
        setNoMoreItems(false); // Reset for the first page
      }
      if (!response.length) {
        setNoMoreItems(true);
      } else {
        const trendIds = response.map((trend) => trend._id).filter(Boolean);
        try {
          const upvote_response = await fetchAllTrendUpvotes(trendIds);
          const upvotesMap = upvote_response.data.data;

          const updatedTrendList = response.map((trend) => ({
            ...trend,
            trendUpvotes: upvotesMap[trend._id] || [],
          }));

          const finalTrendList = updatedTrendList.map((trend) => ({
            ...trend,
            interested: trend?.trendUpvotes.some(
              (item: any) => item?._userId === id
            ),
          }));

          if (pageNumber === 1) {
            setAllTrends(finalTrendList);
          } else {
            setAllTrends((prevTrends) => [
              ...(prevTrends || []),
              ...finalTrendList,
            ]);
          }
        } catch (upvoteError) {
          console.error('Error fetching trend upvotes:', upvoteError);
        }
      }
    } catch (error) {
      console.error('Error fetching trends:', error);
    } finally {
      if (currentUrl.includes('trendCreated')) {
        const urlParams = new URLSearchParams(searchParams);
        urlParams.delete('trendCreated');
        navigate({
          pathname: '/trending',
          search: createSearchParams(urlParams).toString(),
        });
      }
      setIsLoading(false);
    }
  };
  const currentUrl = window.location.href;
  useEffect(() => {
    if (currentUrl.includes('trendCreated')) {
      setRefresh(refresh + 1);
    }
  }, [currentUrl]);

  useEffect(() => {
    setIsTrendId(false);
    setNoMoreItems(false);
    setPageNumber(1);
    setAllTrends([]);
    setDummyTrigger(dummyTrigger + 1);
  }, [refresh, trendId, trendName]);

  useEffect(() => {
    if (trendId.length > 1) {
      setIsTrendId(true);
    }
    if (noMoreItems) {
      return;
    }
    getTrends();
    // pageNumber, trendId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dummyTrigger]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  return (
    <>
      <Helmet>
        <title>White Collar Professional | Trends</title>
        <meta
          name="description"
          content="Popular discussions, articles, and topics, to stay informed on key industry trends, engage in conversations, and discover valuable resources."
        />
        <meta
          name="keywords"
          content="WCP, White Collar Professional, Chartered accountant, Company secretary, Cost accountant, Advocate, Lawyer, Legal, Trending, Trending news, Information, Insights, Highlights"
        />

        <link rel="canonical" href="/trending" />
      </Helmet>
      <Layout contentBordered>
        <div className={styles['trend-main-wrapper']}>
          <div className={styles['filter-wrapper']}>
            <div className={styles['trends-filter-hr']} />
            <div className={styles['trends-filter-label']}>Sort By</div>
            <div className={styles['trends-filter-btns']}>
              <button
                className={sortBy === 'updatedOn' ? styles['active'] : ''}
                onClick={() => {
                  setSortBy('updatedOn');
                  setRefresh(refresh + 1);
                }}
              >
                Most Trending
              </button>
              <button
                className={sortBy === 'upvoteCount' ? styles['active'] : ''}
                onClick={() => {
                  setSortBy('upvoteCount');
                  setRefresh(refresh + 1);
                }}
              >
                Most Liked
              </button>
              <button
                className={sortBy === 'createdOn' ? styles['active'] : ''}
                onClick={() => {
                  setSortBy('createdOn');
                  setRefresh(refresh + 1);
                }}
              >
                Most Recent
              </button>
            </div>
          </div>
          {allTrends?.map((trend, idx) => (
            <div key={idx}>
              <TrendView data={trend} />
            </div>
          ))}
          {!isTrendId && !isLoading && allTrends && (
            <div className={styles.loadMoreBtWrapper}>
              {noMoreItems ? (
                <>
                  You're all caught up! &nbsp;
                  <Button
                    className={styles['topScroll']}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Go to Top
                  </Button>
                </>
              ) : (
                <Button
                  color="transparent"
                  className={styles.loadMoreBtn}
                  onClick={loadMore}
                >
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ color: '#1a1a1a' }}
                  />
                </Button>
              )}
            </div>
          )}
        </div>
        {window.innerWidth < 700 && <CreateTrend />}

        {window.innerWidth < 700 && <BottomBar />}
        <SignupModal
          signupModal={signupModal}
          setSignupModal={setSignupModal}
        />
      </Layout>
    </>
  );
};

export default Trend;
