import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
// import InactiveStepIcon from "../../assets/InactiveStep.png";
// import ActiveStepIcon from "../../assets/activeStep.png";
// import CompletedStepIcon from "../../assets/completedStep.png";
const Stepper = ({ stepComponents, onStepChange, step }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleStepChange = (stepCount) => {
    if (currentStep == 1 && stepCount == 0) {
      setCurrentStep(currentStep);
      if (typeof onStepChange === 'function') {
        onStepChange(currentStep);
      }
    } else {
      setCurrentStep(stepCount);
      if (typeof onStepChange === 'function') {
        onStepChange(stepCount);
      }
    }
  };

  const handleStep1Change = (stepCount) => {
    setCurrentStep(currentStep);
    if (typeof onStepChange === 'function') {
      onStepChange(currentStep);
    }
  };

  useEffect(() => {
    setCurrentStep(step);
  }, [step]);
  return (
    <div className="wc-stepper">
      <div className="stepper-progress">
        <div className="stepper-indicator" />
        <div
          className="stepper-indicator-completed"
          style={{
            width: `calc(${
              Math.floor(
                (((currentStep === 0 ? currentStep : currentStep + 1) /
                  stepComponents?.length) *
                  100) /
                  5
              ) * 5
            }% - 48px)`,
          }}
        />
        {stepComponents?.map((item, index) => (
          <Button
            key={`Step_indicator${index}`}
            className="step-btn"
            color="#fff"
            onClick={
              index != 1
                ? () => handleStepChange(index)
                : () => handleStep1Change(index)
            }
          >
            <img
              src={
                index < currentStep
                  ? '/images/completedStep.png'
                  : index === currentStep
                  ? '/images/activeStep.png'
                  : '/images/inactiveStep.png'
              }
              alt=""
            />
          </Button>
        ))}
      </div>
      <div className="stepper-content">{stepComponents[currentStep]}</div>
    </div>
  );
};

export default Stepper;
