import React, { useEffect, useState } from 'react';
import { SubmittedData } from './events-interface';
import styles from './events.layout.module.css';
import { fetchUpvotes } from 'src/services/post-service/post-service';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import { IUser } from 'src/services/user-service/user-service.interface';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { resolveBEAsset } from 'src/utils/urls';
import { fetchEvents } from 'src/services/event-service/event-service';

export default function RecentEvents() {
  const [loading, setLoading] = useState(true);
  const [newSearch, setNewSearch] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [submittedData, setSubmittedData] = useState<SubmittedData[]>([]);
  const today = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>('');
  const [dummyTrigger, setDummyTrigger] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [eventsCompleted, setEventsCompleted] = useState<boolean>(false);
  const profileId = localStorage.getItem('id') || '';
  const [userDetail, setUserDetail] = useState<IUser | null>(null);
  const navigate = useNavigate();
  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setUserDetail(userRes);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId);
    }
  }, [profileId]);

  const getEvents = async () => {
    setLoading(true);
    const res = await fetchEvents(pageNumber, startDate, endDate);

    let upvotes: { data: any };
    if (userDetail?._id) {
      upvotes = await fetchUpvotes(
        userDetail?._id,
        '_postId',
        res.data.map((event) => event._id)
      );
    } else {
      upvotes = { data: [] };
    }
    const existingIds = new Set(submittedData.map((data) => data.id));
    const newEvents = res?.data
      .filter((event) => !existingIds.has(event._id))
      .map((event) => ({
        id: event._id,
        eventName: event?.title,
        joiningUrl: event?.link,
        eventDesc: event?.content,
        eventImage: event?.attachments[0]?.path,
        interested: upvotes?.data.find(
          (upvoteObj: any) => upvoteObj._postId === event._id
        ),
        startDateTime: event?.startedOn,
        endDateTime: event?.endedOn,
        upvoteCount: event?.upvoteCount,
        userId: event?._userId as unknown as string,
        cpeCredit: event?.cpeCredit,
        eventType: event?.eventType,
      }));

    setSubmittedData([...submittedData, ...newEvents]);
    setLoading(false);
    setNewSearch(false);
  };
  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    setPageNumber(1);
    setEventsCompleted(false);
    setSubmittedData([]);
    setNewSearch(true);
    setDummyTrigger(dummyTrigger + 1);
  }, [refresh]);
  return (
    <div className="recent-events">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Carousel
          autoPlay={true}
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          className={styles['recent-event-carousel']}
        >
          {submittedData.map((data) => (
            <div
              key={data.id}
              onClick={() => navigate(`/events?eventId=${data.id}`)}
            >
              {data.eventImage ? (
                <img
                  src={resolveBEAsset(data.eventImage || '')}
                  alt={data.eventName}
                  className={styles['recent-event-carousel-img']}
                />
              ) : (
                <img
                  src="/images/eventsDefaultImage.png"
                  alt={data.eventName}
                  style={{}}
                  className={styles['recent-event-carousel-img']}
                />
              )}
              <p
                style={{
                  marginTop: '10px',
                  position: 'static',
                  fontSize: '12px',
                  fontWeight: '400',
                }}
              >
                {data.eventName}
              </p>
            </div>
          ))}
        </Carousel>
      )}
      <div
        className={styles['recent-event-readMore']}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate('/events');
        }}
      >
        <span>Know more &gt;&gt; </span>
      </div>
    </div>
  );
}
