export const formats = [
  'size',
  'bold',
  'italic',
  'align',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'link',
];
export const modules = {
  toolbar: [
    [
      'bold',
      'italic',
      'underline',
      { list: 'ordered' },
      { list: 'bullet' },
      { align: 'center' },
      { align: 'right' },
      'link',
    ],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageCompress: {
    quality: 0.4,
    maxWidth: 700,
    maxHeight: 700,
    imageType: 'image/jpeg',
    debug: true,
    suppressErrorLogging: false,
    insertIntoEditor: undefined,
  },
};

export const resizeImage = (
  file: File,
  targetSizeKB: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

        const maxSize = targetSizeKB * 1024;
        let width = img.width;
        let height = img.height;

        const ratio = width / height;
        if (width > height) {
          width = Math.min(Math.sqrt(maxSize / ratio), img.width);
          height = width / ratio;
        } else {
          height = Math.min(Math.sqrt(maxSize * ratio), img.height);
          width = height * ratio;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        const resizedDataUrl = canvas.toDataURL(file.type);
        resolve(resizedDataUrl);
      };
      img.onerror = (error) => {
        reject(error);
      };
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
