import { ReactNode } from 'react';
import { IAttachment } from 'src/services/post-service/post-service.interface';
import styles from './common.module.css';

export function renderIcon(attachment: IAttachment, index: number): ReactNode {
  if (attachment.extension.includes('pdf')) {
    return (
      <div className={styles.attachmentContainer}>
        <img
          src="/images/pdfFile.svg"
          width={0}
          height={0}
          alt="pdf icon"
          style={{ width: '50%', height: 'inherit' }}
          key={`${attachment.name}_${index}`}
        />
        <span>{attachment.originalName}</span>
      </div>
    );
  } else {
    return (
      <div className={styles.attachmentContainer}>
        <img
          src="/images/attachmentDefault.png"
          width={0}
          height={0}
          alt="attachment icon"
          style={{ width: '50%', height: 'inherit' }}
          key={`${attachment.name}_${index}`}
        />
        <span>{attachment.originalName}</span>
      </div>
    );
  }
}

export const attchmentList = [
  { icon: '/images/imageIcon.svg', text: 'Image', accept: 'image/*' },
  { icon: '/images/video-icon.svg', text: 'Video', accept: 'video/*' },
  { icon: '/images/pdfIcon.svg', text: 'PDF', accept: '.pdf' },
  {
    icon: '/images/documentLogo.svg',
    text: 'File',
    accept:
      '.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
];
