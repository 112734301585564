import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SignupNavbar from 'src/components/stepper/navbar';
import SignupForm from 'src/components/stepper/signUpForm';

export default function Signup() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <div className="wc-signup">
      <SignupNavbar />
      <SignupForm />
    </div>
  );
}
