import { Button } from 'reactstrap';
import styles from './dashboardList.module.css';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Request } from 'src/components/request';
import { UserContext } from 'src/context/user.context';
import {
  deletePostUpvote,
  postPostUpvote,
} from 'src/services/post-service/post-service';
import { IPost, Pin } from 'src/services/post-service/post-service.interface';
import { IUser } from 'src/services/user-service/user-service.interface';
import { formatDateStr } from 'src/utils/dateUtil';
import { trimWorld } from 'src/utils/text';
import AttachmentList from '../../attachment-list/attachment-list';
import AvatarRow from '../../avatar-row/avatar-row';
import { handleRequestSend } from 'src/utils/messageUtils';
import SignupModal from 'src/components/stepper/SignupModal';
import PinButton from 'src/components/pin-button';

const DashboardListItem = ({
  data: originalData,
  profilePage = false,
}: {
  data: IPost;
  profilePage?: boolean;
}) => {
  const [data, setData] = useState(originalData);
  const router = useNavigate();
  const { id } = useContext(UserContext);
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  const user = JSON.parse(localStorage.getItem('user') || '{}') as IUser;
  const [isMobile, setIsMobile] = useState(false);
  const loggedInUserId = localStorage.getItem('id') || '';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const addUpvote = async () => {
    if (data) {
      setLoadingUpvotes(true);
      const upvote = await postPostUpvote(data._id);
      setData((prevData) => ({
        ...prevData,
        upvotes: [{ ...upvote, _userId: user }],
        upvoteCount: (prevData.upvoteCount || 0) + 1,
      }));
      setLoadingUpvotes(false);
      setHasUpVotedPost((prev) => !prev);
    }
  };

  const removeUpvote = async (upvoteId: string) => {
    if (data) {
      setLoadingUpvotes(true);
      await deletePostUpvote(data._id, loggedInUserId);

      setData((prevData) => ({
        ...prevData,
        upvotes: [],
        upvoteCount: prevData.upvoteCount ? prevData.upvoteCount - 1 : 0,
      }));
      setLoadingUpvotes(false);
      setHasUpVotedPost((prev) => !prev);
    }
  };

  const self = data._userId?._id === id;

  const navigateToPost = () => {
    router(`/post?postId=${data._id}`);
  };

  const [hasUpVotedPost, setHasUpVotedPost] = useState(
    data?.postUpvotes?.some(
      (postUpvotes) => postUpvotes._userId === loggedInUserId
    )
  );
  const postPageUrl = `https://community.whitecollarprofessional.com/post`;
  const [isCopying, setIsCopying] = useState(false);

  const handleCopyPost = (data: any) => {
    setIsCopying(true);
    navigator.clipboard.writeText(`${postPageUrl}?postId=${data?.id}`);
    setTimeout(() => {
      setIsCopying(false);
    }, 1000);
  };

  return (
    <>
      <div className={styles['dashboard-list-item']}>
        {isMobile ? (
          <div className={styles['item-windows-header']}>
            <AvatarRow showMembership data={data._userId as IUser} />
            <div className={styles['item-title']} style={{ marginBottom: '0' }}>
              {data?.type === 'WORK' ? (
                <div
                  className={styles['item-type']}
                  style={{
                    backgroundColor: '#CDC0F2',
                  }}
                >
                  Insight
                </div>
              ) : (
                <div
                  className={styles['item-type']}
                  style={{
                    backgroundColor: '#C0DAF2',
                  }}
                >
                  Q & A
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles['item-windows-header']}>
            <AvatarRow showMembership data={data._userId as IUser} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                className={styles['item-title']}
                style={{ marginBottom: '0' }}
              >
                {data?.type === 'WORK' ? (
                  <div
                    className={styles['item-type']}
                    style={{
                      backgroundColor: '#CDC0F2',
                    }}
                  >
                    Insight
                  </div>
                ) : (
                  <div
                    className={styles['item-type']}
                    style={{
                      backgroundColor: '#C0DAF2',
                    }}
                  >
                    Q & A
                  </div>
                )}
              </div>

              <span className={styles['post-date']}>
                {formatDateStr(data.createdOn)}
              </span>
            </div>
          </div>
        )}

        {data?.title && (
          <h3
            className={styles['item-title']}
            onClick={() => {
              !token ? setSignupModal(true) : navigateToPost();
            }}
          >
            {trimWorld(data?.title || '', 30)}
            {isMobile && <span>{formatDateStr(data.createdOn)}</span>}
          </h3>
        )}

        {data?.content && (
          <>
            <p
              onClick={() => {
                !token ? setSignupModal(true) : navigateToPost();
              }}
              className={styles['item-desc']}
              dangerouslySetInnerHTML={{ __html: data?.content }}
            />
          </>
        )}

        {data?.attachments?.length > 0 && (
          <div className={styles['attachment-list']}>
            <AttachmentList attachments={data.attachments} data={data} />
          </div>
        )}
        {window.innerWidth > 700 && (
          <div className={styles['dashboard-post-hr']} />
        )}
        <div className={styles['item-interaction']}>
          <Button
            className={classNames(
              styles['interaction-btn'],
              styles['answer-btn']
            )}
            color="transparent"
            onClick={() => {
              !token ? setSignupModal(true) : navigateToPost();
            }}
          >
            {data.type === 'WORK' ? (
              <>
                <img src="/images/commentIcon.png" alt="" />
                &nbsp;Comment
              </>
            ) : (
              <>
                <img src="/images/answerIcon.png" alt="" />
                &nbsp;Answer
              </>
            )}
            &nbsp;({data?.commentCount})
          </Button>
          {data.type === 'QUESTION' && (
            <Request
              onReset={() => {
                console.log('Reset');
              }}
              onRequest={(args) => {
                handleRequestSend(
                  user,
                  args as unknown as IUser[],
                  data as IPost,
                  'POST'
                );
              }}
              label="Request"
            />
          )}
          {data.type === 'WORK' && (
            <Request
              onReset={() => {
                console.log('Reset');
              }}
              onRequest={(args) => {
                handleRequestSend(
                  user,
                  args as unknown as IUser[],
                  data as IPost,
                  'POST'
                );
              }}
              label="Share"
            />
          )}
          <PinButton
            self={self}
            pinId={data?.postPins?.length ? true : false}
            selectedQues={data}
            type={Pin.POST}
          />
          {data?.type === 'WORK' && (
            <Button
              className={styles['interaction-btn']}
              color="transparent"
              onClick={() => {
                if (hasUpVotedPost) {
                  const upvoteId = data?.postUpvotes?.find(
                    (postUpvotes) => postUpvotes._userId === loggedInUserId
                  )?._id;
                  if (upvoteId) {
                    removeUpvote(upvoteId);
                  }
                } else {
                  addUpvote();
                }
              }}
              disabled={!token}
            >
              <img
                src={
                  hasUpVotedPost
                    ? '/images/upvotedIcon.png'
                    : '/images/upvoteIcon.png'
                }
                alt=""
              />
              &nbsp;Upvote ({data.upvoteCount})
              {loadingUpvotes && (
                <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
              )}
            </Button>
          )}
          <div style={{ margin: '0 10px 0 auto' }}>
            {!isCopying ? (
              <FontAwesomeIcon
                icon={faCopy}
                onClick={() => handleCopyPost(data)}
                style={{
                  cursor: 'pointer',
                  fontSize: '18px',
                  color: '#666',
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheck}
                className={styles['check-icon']}
              />
            )}
          </div>
          {isCopying && window.innerWidth <= 700 && (
            <div
              style={{
                position: 'fixed',
                bottom: '110px',
                width: '100%',
                margin: '0 auto',
                paddingRight: '12px',
                zIndex: '999',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  padding: '4px 8px',
                  border: '1px solid #ccc',
                  backgroundColor: '#e6e6e6',
                  width: 'max-content',
                  color: '#1a1a1a',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                }}
              >
                Copied to Clipboard
              </div>
            </div>
          )}
        </div>
      </div>

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default DashboardListItem;
