import React from 'react';

interface UpdateImageProps {
  fill: string;
  strokeWidth?: string;
  strokeColor?: string;
  height?: number;
  width?: number;
}

const UpdateImage: React.FC<UpdateImageProps> = ({ fill }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1616 4804 c-153 -37 -284 -153 -339 -302 -19 -50 -22 -84 -25 -269
l-4 -213 -433 0 c-474 0 -493 -2 -597 -63 -91 -53 -175 -166 -202 -272 -23
-85 -23 -845 -1 -950 19 -89 70 -193 134 -273 l51 -62 0 -848 c0 -917 -1 -902
54 -1010 34 -66 100 -135 169 -176 119 -70 -16 -66 2137 -66 2153 0 2018 -4
2137 66 69 41 135 110 169 176 55 108 54 93 54 1010 l0 848 51 62 c64 80 115
184 134 273 22 105 22 865 -1 950 -27 106 -111 219 -202 272 -104 61 -123 63
-597 63 l-433 0 -4 213 c-3 185 -6 219 -25 269 -56 152 -185 265 -344 303 -92
21 -1792 21 -1883 -1z m1850 -303 c23 -10 54 -36 70 -57 l29 -37 3 -194 3
-193 -1011 0 -1011 0 3 193 3 194 29 37 c16 21 47 47 70 57 39 18 83 19 906
19 823 0 867 -1 906 -19z m1268 -795 c18 -7 44 -29 57 -47 l24 -34 0 -420 0
-420 -30 -59 c-89 -177 -346 -333 -734 -447 -227 -66 -530 -122 -801 -149 -63
-6 -127 -13 -142 -15 l-26 -4 -4 97 c-3 83 -6 102 -27 131 -43 60 -49 61 -493
61 -364 0 -406 -2 -437 -18 -62 -31 -76 -61 -79 -173 -3 -87 -6 -99 -20 -95
-10 2 -53 7 -97 11 -356 29 -761 113 -1042 215 -198 72 -416 205 -491 299 -18
22 -45 64 -60 93 l-27 53 0 420 0 420 24 34 c46 65 -119 60 2229 61 1805 0
2148 -2 2176 -14z m-4166 -1555 c316 -159 823 -282 1347 -326 136 -11 131 -9
151 -71 77 -245 343 -387 616 -330 175 38 321 167 372 329 20 64 -9 55 256 82
323 34 680 107 937 191 107 35 329 128 348 145 5 5 13 9 17 9 5 0 7 -333 6
-739 -3 -729 -3 -740 -24 -767 -11 -15 -33 -37 -48 -48 l-27 -21 -1959 0
-1959 0 -27 21 c-15 11 -37 33 -48 48 -21 27 -21 38 -24 767 -1 406 0 739 3
739 3 0 31 -13 63 -29z m2210 -180 c-3 -125 -4 -131 -33 -172 -44 -61 -93 -84
-185 -84 -92 0 -141 23 -185 84 -29 41 -30 47 -33 172 l-4 129 222 0 222 0 -4
-129z"
        />
      </g>
    </svg>
  );
};

export default UpdateImage;
