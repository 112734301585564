import AvatarRow from 'src/components/avatar-row/avatar-row';
import styles from './searchList.module.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function WorkForce({
  userInfos,
  orgName,
  isModalOpen,
  setIsModalOpen,
}: any) {
  const [orgnisation, setOrganisation] = useState('');
  useEffect(() => {
    setOrganisation(() => orgName);
  }, [orgName]);

  const router = useNavigate();
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      //@ts-ignore
      toggle={() => setIsModalOpen((prev) => !prev)}
      centered
    >
      {/* @ts-ignore */}
      <ModalHeader toggle={() => setIsModalOpen((prev) => !prev)}>
        <div className={styles.workforceHeading}>
          <FontAwesomeIcon icon={faBuilding} />
          &nbsp; {orgnisation}
        </div>
      </ModalHeader>
      <ModalBody style={{ paddingTop: '0px', borderRadius: '8px' }}>
        {' '}
        <div className={styles.workforceTitle}>
          <span style={{ textDecoration: 'underline' }}>Current Workforce</span>
        </div>
        <div className={styles.workforceWrapper}>
          {userInfos
            .filter((item: any) => !item.endedOn)
            .map((user: any) => (
              <div
                style={{ display: 'flex', gap: '4px' }}
                key={user._userId}
                onClick={() => router(`/profile?profileId=${user._userId}`)}
              >
                <AvatarRow
                  // @ts-ignore
                  data={user?.user}
                  showMembership
                  position={user?.position}
                />
              </div>
            ))}
        </div>
        {userInfos.some((item: any) => item.endedOn) && (
          <>
            <div className={styles.workforceTitle}>
              <span style={{ textDecoration: 'underline' }}>Ex-Employees</span>
            </div>
            <div className={styles.workforceWrapper}>
              {userInfos
                .filter((item: any) => item.endedOn)
                .map((user: any) => (
                  <div
                    style={{ display: 'flex', gap: '4px' }}
                    key={user._userId}
                    onClick={() => router(`/profile?profileId=${user._userId}`)}
                  >
                    {/* @ts-ignore */}
                    <AvatarRow
                      // @ts-ignore
                      data={user?.user}
                      showMembership
                      position={user.position}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
